import { useDispatch, useSelector } from "react-redux";
import {
  setActiveChat,
  setInstanceContacts,
  setSpeedFiles,
  setForwardContacts,
  setInstanceList,
  setListPagination,
  setLoadingForMore,
  setGroupParticipants,
  setGroupParticipantsPaging,
} from "../redux/action";
import Api from "../Network";
import { useLayoutEffect, useRef } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { uniqBy } from "lodash";
import { IContact } from "../types/Contact";

export const ChatListFilter = () => {
  const dispatch = useDispatch();
  const {
    activeVendor,
    vendorChats,
    instanceList,
    activeChat,
    listPaging,
    chatTabType,
    userInfo,
    groupParticipantsPaging,
    groupParticipants,
  } = useSelector((state: any) => state.setReducer);

  const instanceListRef = useRef(instanceList);
  const vendorChatsRef = useRef(vendorChats);
  const activeVendorIdRef = useRef(activeVendor);

  const { t } = useTranslation();

  const handleLoadVendorForward = async (
    pageNumber = 1,
    pageSize = 20,
    loadMore?: boolean,
    vendorId?: string
  ) => {
    const response = await Api.get(
      `instance/getContacts?instanceId=${
        vendorId ? vendorId : activeVendor?._id
      }&type=All&page=${pageNumber}&pageSize=${pageSize}`
    );

    dispatch(
      setForwardContacts(loadMore ? [...vendorChats, ...response] : response)
    );
  };

  const handleLoadVendorChats = async (
    pageNumber = 1,
    pageSize = 20,
    loadMore?: boolean,
    vendorId?: string
  ) => {
    if (!loadMore) {
      dispatch(setInstanceContacts(null));
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: false,
        })
      );
    }
    vendorId = vendorId ? vendorId : activeVendorIdRef.current;
    dispatch(setLoadingForMore(true));
    const response = await Api.get(
      `instance/getContacts?instanceId=${
        vendorId
      }&type=${chatTabType}&page=${pageNumber}&pageSize=${pageSize}`
    );

    dispatch(setLoadingForMore(false));

    if (response?.length === 0)
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: true,
        })
      );

    if (loadMore && response?.length >= 0)
      dispatch(
        setListPagination({
          pageNumber: listPaging?.pageNumber + 1,
        })
      );
    if (activeVendorIdRef.current !== vendorId) return;
    dispatch(
      setInstanceContacts(
        uniqBy(loadMore ? [...vendorChats, ...response] : response, "_id")
      )
    );
  };

  const handleFilterVendorChats = async (
    chatContent: string,
    pageNumber = 1,
    number = 20,
    loadMore?: boolean,
    from?: number,
    to?: number
  ) => {
    if (!loadMore) {
      dispatch(setInstanceContacts(null));
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: false,
        })
      );
    }

    dispatch(setLoadingForMore(true));

    const response = await Api.get(
      `search?instanceId=${
        activeVendor?._id
      }&searchContent=${chatContent}&page=${pageNumber}&pageSize=${number}&typeOfContact=${chatTabType}${
        from && to ? `&from=${from}&to=${to}&type=5` : ""
      }`
    );

    dispatch(setLoadingForMore(false));

    if (response?.length === 0)
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: true,
        })
      );

    if (loadMore && response?.length >= 0)
      dispatch(
        setListPagination({
          pageNumber: listPaging?.pageNumber + 1,
        })
      );

    dispatch(
      setInstanceContacts(
        uniqBy(loadMore ? [...vendorChats, ...response] : response, "_id")
      )
    );
    dispatch(setActiveChat(null));
  };

  const handleFilterVendorChatsByStatus = async (
    statusId: string,
    pageNumber = 1,
    number = 20,
    loadMore?: boolean
  ) => {
    if (!loadMore) {
      dispatch(setInstanceContacts(null));
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: false,
        })
      );
    }

    dispatch(setLoadingForMore(true));

    const response = await Api.get(
      `get-by-status?instanceId=${activeVendor?._id}&statusId=${statusId}&page=${pageNumber}&pageSize=${number}&type=${chatTabType}`
    );

    dispatch(setLoadingForMore(false));

    if (response?.length === 0)
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: true,
        })
      );

    if (loadMore && response?.length >= 0)
      dispatch(
        setInstanceContacts(
          uniqBy(loadMore ? [...vendorChats, ...response] : response, "_id")
        )
      );

    dispatch(
      setInstanceContacts(loadMore ? [...vendorChats, ...response] : response)
    );
    dispatch(setActiveChat(null));
  };

  const handleFilterVendorChatsByAgent = async (
    agentId: string,
    pageNumber = 1,
    number = 20,
    loadMore?: boolean
  ) => {
    if (!loadMore) {
      dispatch(setInstanceContacts(null));
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: false,
        })
      );
    }

    dispatch(setLoadingForMore(true));

    const response = await Api.get(
      `get-by-agent?instanceId=${activeVendor?._id}&agentId=${agentId}&page=${pageNumber}&pageSize=${number}&type=${chatTabType}`
    );

    dispatch(setLoadingForMore(false));

    if (response?.length === 0)
      dispatch(
        setListPagination({
          pageNumber: 1,
          stopLoading: true,
        })
      );

    if (loadMore && response?.length >= 0)
      dispatch(
        setListPagination({
          pageNumber: listPaging?.pageNumber + 1,
        })
      );

    dispatch(
      setInstanceContacts(
        uniqBy(loadMore ? [...vendorChats, ...response] : response, "_id")
      )
    );
    dispatch(setActiveChat(null));
  };

  const handleChangeStatus = async (
    items: IContact[] | IContact,
    statusContact: {
      name: string;
      id: string;
      class: string;
      color: string;
      isDone?: boolean;
    },
    doneBody?: any
  ) => {
    try {
      // in the old version of this funtion single contact was passed to this function
      // we needed to make it array to handle multi select
      // so if it's not array convert it to array
      if (!Array.isArray(items)) items = [items];

      const instanceId = activeVendorIdRef.current;
      const contactsId = items?.map((item) => item._id);

      await Api.post(
        `contact/changeStatus?instanceId=${instanceId}&statusId=${statusContact?.id}`,
        { contactsId }
      );

      const updatedItems: { [key: string]: IContact } = {};

      items.forEach((item) => {
        updatedItems[item._id] = {
          ...item,
          status: {
            _id: statusContact?.id,
            active: false,
            name: statusContact?.name,
            color: statusContact?.color,
          },
        };
      });

      let updatedContacts;
      if (statusContact?.isDone) {
        const body = new FormData();
        body.append("typeId", doneBody?.typeId);
        body.append("comment", doneBody?.comment);
        body.append("statusId", statusContact?.id);

        activeVendor?.chatReasonPopUp &&
          items.length === 1 &&
          (await Api.post(
            `contact/updateContact?instanceId=${instanceId}&contactId=${items[0]._id}&from=WEB`,
            body
          ));
        if (updatedItems[activeChat?._id]) dispatch(setActiveChat(null));
        // this may no be needed if we inhanced the add-contact and remove-contact sockets to handle this
        if (userInfo.user_type !== 0)
          updateInstanceChatsCount({
            instanceId: instanceId,
            type: "dec",
            amount: items.length,
          });
        updatedContacts = vendorChatsRef.current?.filter(
          (item: IContact) => !updatedItems[item._id]
        );
      } else {
        if (items.length === 1) dispatch(setActiveChat(items[0])); // if one contact status changed make him active chat

        updatedContacts = vendorChatsRef.current?.map((item: any) =>
          updatedItems[item._id] ? { ...updatedItems[item._id] } : item
        );
      }

      dispatch(setInstanceContacts(updatedContacts));
      message.success(
        `${t("moveToDone.Status_Change_to")} ${statusContact?.name}`
      );
    } catch (error) {
      console.error("Error updating array item:", error);
    }
  };

  const updateInstanceChatsCount = ({
    instanceId,
    chatsCount,
    type,
    amount = 1,
  }: {
    instanceId: string;
    chatsCount?: number;
    type?: "inc" | "dec";
    amount?: number;
  }) => {
    const updatedInstance = instanceListRef.current?.map((instance: any) => {
      if (instance?._id === instanceId) {
        return {
          ...instance,
          chatsCount: chatsCount
            ? chatsCount
            : type === "inc"
              ? instance.chatsCount + amount
              : instance.chatsCount - amount,
        };
      } else return instance;
    });

    dispatch(setInstanceList(updatedInstance));
  };

  const handleGetParticipants = async (
    page: number = 1,
    pageSize: number = 20,
    loadMore?: boolean
  ) => {
    try {
      if (!loadMore) {
        dispatch(setGroupParticipants([]));
        dispatch(
          setGroupParticipantsPaging({
            pageNumber: 1,
            stopLoading: false,
          })
        );
      }
      dispatch(setLoadingForMore(true));
      const response = await Api.get(
        `wAppGroups/getParticicpants?instanceId=${activeChat?.instance}&_id=${activeChat?.groupMetadata?._id}&page=${page}&pageSize=${pageSize}`
      );
      dispatch(setLoadingForMore(false));

      if (response?.length === 0)
        dispatch(
          setGroupParticipantsPaging({
            pageNumber: 1,
            stopLoading: true,
          })
        );

      if (loadMore && response?.length >= 0)
        dispatch(
          setGroupParticipantsPaging({
            pageNumber: groupParticipantsPaging?.pageNumber + 1,
          })
        );
      dispatch(
        setGroupParticipants(
          loadMore ? [...groupParticipants, ...response] : response
        )
      );
    } catch (error) {}
  };

  useLayoutEffect(() => {
    instanceListRef.current = instanceList;
  }, [instanceList]);
  useLayoutEffect(() => {
    vendorChatsRef.current = vendorChats;
  }, [vendorChats]);
  useLayoutEffect(() => {
    activeVendorIdRef.current = activeVendor?._id;
  }, [activeVendor]);

  return {
    handleLoadVendorChats,
    handleLoadVendorForward,
    handleFilterVendorChats,
    handleFilterVendorChatsByStatus,
    handleFilterVendorChatsByAgent,
    handleChangeStatus,
    updateInstanceChatsCount,
    handleGetParticipants,
  };
};
