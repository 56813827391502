import { ComponentType, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { setUserInfo } from "../redux/action";
import Spinner from "../Component/LoadingSpiner";
import Api from "../Network";
import { message } from "antd";
import { deleteQueryPresister } from "../Utilis/createQueryPresister";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../Component/ErrorFallback/ErrorFallback";

export interface RouteProps {
  component: ComponentType<any>;
  isAuthenticated: boolean;
  [key: string]: any;
  exact?: boolean;
}

export const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  isAuthenticated,
  exact,
  ...rest
}) => {
  const { userInfo } = useSelector((state: any) => state.setReducer);
  const dispatch = useDispatch();

  async function handleLogout() {
    try {
      await Api.post("auth/logout", {
        company: userInfo?.company_instance?._id,
      });
      localStorage.removeItem("token");
      localStorage?.removeItem("socketUrl");
      localStorage?.removeItem("refresh-token");
      deleteQueryPresister();

      dispatch(setUserInfo(null));
      window.location.reload();
    } catch (error) {
      message.error("failed to logout");
    }
  }

  if (!userInfo) return <Spinner />;

  if (!isAuthenticated) {
    handleLogout();
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Route
        {...rest}
        exact={exact}
        render={(props) =>
          isAuthenticated ? (
            <Suspense fallback={<Spinner />}>
              <Component {...props} />
            </Suspense>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </ErrorBoundary>
  );
};

export const PublicRoute: React.FC<RouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => <Route {...rest} exact render={(props) => <Component {...props} />} />;
