import { useImageChecker } from "../../Hooks/useCheckImgUrl";

type ImgProps = {
  imageUrl: string;
  defaultIcon: string;
  useSrc?: boolean;
};

export const ImageWithChecker: React.FC<ImgProps> = ({
  imageUrl,
  defaultIcon,
  useSrc,
}) => {
  const imgElement = useImageChecker(imageUrl, defaultIcon, 47, 47, useSrc);

  return <div>{imgElement}</div>;
};
