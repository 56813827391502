import { useState } from "react";
import { Table } from "../../../Component/Table";
import moment from "moment";
import { TableEditDelete } from "../../../Component/TableEditDelete/TableEditDelete";
// import { AiAssistantModal } from "../../../Component/AiAssistantModal/AiAssistantModal";
import "./style.scss";
import { useDelete } from "../../../Hooks/configration/useList";
import { talbeQueryKeys } from "../../../Hooks/configration/tableQueryKeys";
import { limitText } from "../../../Utilis/limitText";
import { Tooltip } from "antd";
import { ContentModal } from "../../../Component/ContentModal/ContentModal";
import { AiAssistantModal } from "../../../Component/AiAssistantModal/AiAssistantModal";

const AiAssistants = () => {
  const [openAiAssistantModal, setOpenAiAssistantModal] = useState<any>();
  const [openContentModal, setOpenContentModal] = useState("");

  const { mutateAsync: deleteAiAssistant } = useDelete(
    talbeQueryKeys.aiAssistant
  );

  return (
    <div className="aiAssistants-configuration">
      <Table
        tableQueryKey={talbeQueryKeys.aiAssistant}
        tableTitle="All Ai Agents"
        onClickNewButton={() => setOpenAiAssistantModal({})}
        rowClassName={(aiAssistant) => (aiAssistant.isDeleted ? "deleted" : "")}
        columns={[
          {
            title: "name",
            dataIndex: "name",
            sorter: true,
            render: (content) => limitText(content, 30),
          },
          {
            title: "description",
            dataIndex: "description",
            sorter: true,
            render: (content) => (
              <Tooltip title={"click to see more"}>
                <p
                  onClick={() => setOpenContentModal(content)}
                  className="assistant-content"
                >
                  {limitText(content || "-", 50)}
                </p>
              </Tooltip>
            ),
          },
          {
            title: "instructions",
            dataIndex: "instructions",
            sorter: true,
            render: (content) => (
              <Tooltip title={"click to see more"}>
                <p
                  onClick={() => setOpenContentModal(content)}
                  className="assistant-content"
                >
                  {limitText(content || "-", 50)}
                </p>
              </Tooltip>
            ),
          },
          {
            title: "creation date",
            dataIndex: "createdAt",
            sorter: true,
            render: (createdAt) =>
              createdAt ? moment(createdAt).format("YYYY-MM-DD") : "-",
          },
          {
            title: "actions",
            render: (_, aiAssistant) => {
              return (
                <TableEditDelete
                  handleEdit={() => setOpenAiAssistantModal(aiAssistant)}
                  handleDelete={() => deleteAiAssistant(aiAssistant._id)}
                />
              );
            },
          },
        ]}
      />
      {openContentModal && (
        <ContentModal
          openItem={openContentModal}
          setOpenItem={setOpenContentModal}
        />
      )}
      {openAiAssistantModal && (
        <AiAssistantModal
          openItem={openAiAssistantModal}
          setOpenItem={setOpenAiAssistantModal}
        />
      )}
    </div>
  );
};

export default AiAssistants;
