import { useEffect, useRef, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import "./style.scss";

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  let [isFirstRender, setIsFirstRender] = useState(true);

  let { pathname } = useLocation();

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);

    resetErrorBoundary();
  }, [pathname]);

  return (
    <div className="error-fallback">
      <h1>an error happened in this page</h1>
      <h2>contact support</h2>
    </div>
  );
};
