import { useState, useEffect, useLayoutEffect } from "react";
import { PersonIcon } from "../icons/personIcon";

export const useImageChecker = (
  imageUrl: string,
  defaultIcon: any,
  width: number = 40,
  height: number = 40,
  useSrc?: boolean
) => {
  const [imageExists, setImageExists] = useState(true);

  useLayoutEffect(() => {
    if (!imageUrl) {
      setImageExists(false);
      return;
    }

    const img = new Image();
    img.src = imageUrl;

    const handleImageLoad = () => setImageExists(true);

    const handleImageError = () => setImageExists(false);

    img.onload = handleImageLoad;
    img.onerror = handleImageError;

    // Cleanup event listeners on component unmount
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  const renderedMsg = imageExists ? (
    <img src={imageExists ? imageUrl : defaultIcon} alt="profile" />
  ) : useSrc ? (
    <img src={defaultIcon} alt="default-icon" />
  ) : (
    <span className="image-default">
      <PersonIcon />
    </span>
  );
  return renderedMsg;
};
