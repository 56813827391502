import { IMessage } from "../types/Message";
import { ChatTabTypes } from "../types/types";

export const setActiveChat = (value: any) => ({
  type: "SET_ACTIVE_CHAT",
  payload: value,
});

export const setListPagination = (value: {
  pageNumber: number;
  stopLoading?: boolean;
}) => ({
  type: "SET_PAGING_SIZE",
  payload: {
    pageNumber: value.pageNumber,
    stopLoading: value?.stopLoading,
  },
});

export const setGroupParticipantsPaging = (value: {
  pageNumber: number;
  stopLoading?: boolean;
}) => ({
  type: "SET_GROUP_PARTICIPANTS_PAGING_SIZE",
  payload: {
    pageNumber: value.pageNumber,
    stopLoading: value?.stopLoading,
  },
});

export const setMessageListPagination = (value: {
  pageNumber: number;
  stopLoading?: boolean;
}) => ({
  type: "SET_PAGING_MESSAGES",
  payload: {
    pageNumber: value.pageNumber,
    stopLoading: value?.stopLoading,
  },
});

export const setGroupsList = (value: any) => ({
  type: "SET_GROUPS_LIST",
  payload: value,
});

export const setChatLoading = (value: any) => ({
  type: "SET_CHAT_LOADING",
  payload: value,
});

export const setInstanceList = (value: any) => ({
  type: "SET_INSTANCE_LIST",
  payload: value,
});

export const setInstanceContacts = (value: any) => ({
  type: "SET_VENDOR_LIST",
  payload: value,
});

export const setForwardContacts = (value: any) => ({
  type: "SET_FORWARD_LIST",
  payload: value,
});

export const setUserInfo = (value: any) => ({
  type: "SET_USER_INFO",
  payload: value,
});

export const setChatReasons = (value: any) => ({
  type: "SET_CHAT_REASONS",
  payload: value,
});

export const setSpeedFiles = (value: any) => ({
  type: "SET_SPEED_FILES",
  payload: value,
});

export const setSpeedMessages = (value: any) => ({
  type: "SET_SPEED_MESSAGE",
  payload: value,
});

export const setDriversList = (value: any) => ({
  type: "SET_DRIVERS_LIST",
  payload: value,
});

export const setActiveVendor = (value: any) => ({
  type: "SET_ACTIVE_VENDOR",
  payload: value,
});

export const setLoadingForMore = (value: boolean) => ({
  type: "SET_LOADING",
  payload: value,
});

export const setSelectedChat = (value: any) => ({
  type: "SET_SELECTED_CHAT",
  payload: value,
});

export const setShowSelectedChat = (value: boolean) => ({
  type: "SET_SHOW_SELECTED",
  payload: value,
});

export const setIsCommentView = (value: boolean) => ({
  type: "SET_COMMENTS_VIEW",
  payload: value,
});

export const setCommentsList = (value: any) => ({
  type: "SET_COMMENTS_LIST",
  payload: value,
});

export const setPostsList = (value: any) => ({
  type: "SET_POSTS_LIST",
  payload: value,
});

export const setActivePost = (value: any) => ({
  type: "SET_ACTIVE_POST",
  payload: value,
});

export const setActiveComment = (value: any) => ({
  type: "SET_ACTIVE_COMMENT",
  payload: value,
});

export const toggleSiteMuted = () => ({
  type: "TOGGLE_SITE_MUTED",
});

export const setGroupParticipants = (value: any) => ({
  type: "SET_GROUP_PARTICIPANTS",
  payload: value,
});

export const setChatTabType = (type: ChatTabTypes) => ({
  type: "SET_CHAT_TAB_TYPE",
  payload: type,
});

export const refreshMessages = (refresh: boolean) => ({
  type: "REFRESH_MESSAGES",
  payload: refresh,
});

export const setSelectedMessages = (value: { [key: string]: IMessage }) => ({
  type: "SET_SELECTED_MESSAGES",
  payload: value,
});
