import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setReducer } from "./reducer";
import { remindersReducer } from "./remindersReducer/remindersReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  setReducer,
  reminders: remindersReducer,
});

export default persistReducer(persistConfig, rootReducer);
