import { Menu } from "antd";
import "./style.scss";
import { useHistory } from "react-router-dom";
import type { PopconfirmProps } from "antd";
import { message, Popconfirm } from "antd";
import { useDelete, useUpdate } from "../../Hooks/configration/useList";
import { talbeQueryKeys } from "../../Hooks/configration/tableQueryKeys";
import { Dispatch, SetStateAction } from "react";
import { Bot } from "../../types/Bot";
import { useTranslation } from "react-i18next";

type props = {
  bot: Bot;
};

export const AllBotsTableActions = ({ bot }: props) => {
  let history = useHistory();
  const { mutateAsync: updateBot } = useUpdate(talbeQueryKeys.bot);
  let { t } = useTranslation();

  const toggleDeleteBot = async (id: string, deleted: Boolean) => {
    try {
      await updateBot({ id, data: { isDeleted: deleted } });
      message.success(
        `${deleted ? t("AllBotsTableActions.toggleDeleteBotSuccessDeleted") : t("AllBotsTableActions.toggleDeleteBotSuccessRestored")}`
      );
    } catch (error) {
      message.error(`${deleted ? t("AllBotsTableActions.toggleDeleteBotFailedDeleted") : t("AllBotsTableActions.toggleDeleteBotFailedRestored")}`);
    }
  };

  const confirm: PopconfirmProps["onConfirm"] = async (e) => {
    e?.stopPropagation();
    await toggleDeleteBot(bot._id, !bot.isDeleted);
  };

  return (
    <Menu className="bot-table-actions">
      <Menu.Item key="Select" onClick={() => ""}>
        <div>{t("AllBotsTableActions.menuOptionSelect")}</div>
      </Menu.Item>

      <Menu.Item
        key="Edit"
        /*onClick={() => {
          history.push("/configuration/bot-form", bot);
        }}*/
      >
        <div>{t("AllBotsTableActions.menuOptionEdit")}</div>
      </Menu.Item>

      <Menu.Item
        key="Setting"
        /*onClick={() => {
          history.push("/configuration/bot-form", bot);
        }}*/
      >
        <div>{t("AllBotsTableActions.menuOptionSettings")}</div>
      </Menu.Item>

      <Menu.Item key={bot.isDeleted ? "restore-user" : "delete-user"}>
        <Popconfirm
          title={`${bot.isDeleted ? t("AllBotsTableActions.titleAlertRestore") : t("AllBotsTableActions.titleAlertDeleted")}`}
          onConfirm={confirm}
          okText={t("AllBotsTableActions.titleAlertDeletedAcceptButton")}
          cancelText={t("AllBotsTableActions.titleAlertDeletedDeniedButton")}
        >
          <div
            className={bot.isDeleted ? "" : "delete-menu-item"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {bot.isDeleted ? t("AllBotsTableActions.menuOptionRestore") : t("AllBotsTableActions.menuOptionDelete")}
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
};
