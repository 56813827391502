import { Button, Form, Input, Modal, message } from "antd";
import Api from "../../Network";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useUpdate } from "../../Hooks/configration/useList";
import { talbeQueryKeys } from "../../Hooks/configration/tableQueryKeys";

type props = {
  setOpenItem: any;
  openItem: any;
  userId: any;
};

export const ChangePasswordModal = ({
  setOpenItem,
  openItem,
  userId,
}: props) => {
  const { t } = useTranslation();

  const { mutateAsync: updateUser, isPending: loading } = useUpdate(
    talbeQueryKeys.user
  );

  const handlePasswordChange = async (values: any) => {
    try {
      await updateUser({
        id: userId,
        data: {
          password: values.password,
        },
      });
      setOpenItem(null);
    } catch (error) {
      message.error("Password update failed");
    }
  };

  if (!openItem) return null;

  return (
    <Modal
      title={t("general.Change_Password")}
      className="change-password-model mini-form-modal"
      open={!!openItem}
      onOk={() => {}}
      onCancel={() => setOpenItem(null)}
      footer={[]}
      destroyOnClose={true}
      width={800}
    >
      <Form
        onFinish={(values) => handlePasswordChange(values)}
        layout="vertical"
      >
        <Form.Item
          name="password"
          label={t("general.Password")}
          rules={[{ required: true }]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label={t("general.confirmPassword")}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("password and confrim password should be the same")
                );
              },
            }),
          ]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <div className="modal-btns">
          <Button
            loading={loading}
            className="modal-btn done"
            type="primary"
            htmlType="submit"
            onClick={handlePasswordChange}
          >
            {t("general.Done")}
          </Button>

          <Button
            type="default"
            htmlType="button"
            className="modal-btn cancel"
            onClick={() => setOpenItem(null)}
          >
            {t("general.Cancel")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
