import { Modal } from "antd";

type Props = {
  openItem: any;
  setOpenItem: React.Dispatch<any>;
};

export const ContentModal = ({ openItem: content, setOpenItem }: Props) => {
  return (
    <Modal
      className="content-modal mini-form-modal"
      title={"content"}
      open={!!content}
      onOk={() => {}}
      onCancel={() => setOpenItem(null)}
      footer={[]}
      destroyOnClose={true}
    >
      <div
        style={{ maxHeight: "70vh", overflow: "auto", whiteSpace: "pre-wrap" }}
      >
        {content}
      </div>
    </Modal>
  );
};
