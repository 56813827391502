import { useTranslation } from "react-i18next";

type Props = {
  item: {} & {
    name?: string;
    nameAr?: string;
    nameEn?: string;
    nickName?: string;
  };
};

export const NameByLang = ({ item }: Props) => {
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.language === "en"
        ? item.nameEn || item.nameAr || item.nickName || item.name || "-"
        : i18n.language === "ar"
          ? item.nameAr || item.nameEn || item.nickName || item.name || "-"
          : "-"}
    </>
  );
};
