export const LightMode = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1082 18.934C9.64126 18.9338 9.17496 18.8987 8.71322 18.829C6.69106 18.5093 4.83478 17.5199 3.44187 16.0195C2.04895 14.5191 1.20003 12.5946 1.03116 10.5542C0.862288 8.51393 1.38323 6.47599 2.51052 4.767C3.6378 3.05802 5.30615 1.77693 7.24822 1.12904C7.45605 1.05839 7.67949 1.04726 7.89331 1.09692C8.10713 1.14657 8.3028 1.25503 8.45822 1.41004C8.62662 1.57446 8.74615 1.78234 8.80352 2.01059C8.8609 2.23885 8.85387 2.47854 8.78322 2.70304C8.51649 3.57034 8.39717 4.47625 8.43022 5.38304C8.50557 6.80529 8.94712 8.18394 9.71207 9.38532C10.477 10.5867 11.5394 11.5701 12.7962 12.24C13.9283 12.8822 15.2058 13.224 16.5072 13.233C16.7339 13.2362 16.9553 13.3013 17.1476 13.4213C17.3399 13.5413 17.4957 13.7116 17.598 13.9138C17.7004 14.1161 17.7456 14.3424 17.7285 14.5684C17.7114 14.7945 17.6328 15.0115 17.5012 15.196C16.6499 16.3568 15.5367 17.3003 14.2521 17.9499C12.9675 18.5994 11.5477 18.9366 10.1082 18.934ZM7.62822 2.06704C7.60611 2.06728 7.58418 2.07099 7.56322 2.07804C6.34752 2.48147 5.24424 3.16621 4.34313 4.07653C3.44203 4.98685 2.76856 6.09705 2.37752 7.31679C1.98649 8.53653 1.88893 9.83135 2.09278 11.0959C2.29664 12.3605 2.79615 13.5591 3.55068 14.5941C4.3052 15.6292 5.29343 16.4715 6.43495 17.0526C7.57647 17.6336 8.83904 17.9369 10.1199 17.9379C11.4008 17.9389 12.6638 17.6374 13.8062 17.0581C14.9486 16.4788 15.9381 15.638 16.6942 14.604C16.718 14.5699 16.7318 14.5299 16.7341 14.4884C16.7364 14.4469 16.7271 14.4056 16.7072 14.369C16.6892 14.3293 16.6602 14.2954 16.6238 14.2714C16.5874 14.2473 16.5449 14.234 16.5012 14.233C15.033 14.2238 13.5914 13.8397 12.3132 13.117C10.9051 12.3628 9.7156 11.2576 8.8601 9.90856C8.0046 8.55952 7.51206 7.01238 7.43022 5.41704C7.3947 4.39907 7.5299 3.38235 7.83022 2.40904C7.84551 2.35973 7.84717 2.3072 7.83502 2.25702C7.82288 2.20685 7.79737 2.16089 7.76122 2.12404C7.74405 2.10604 7.72342 2.09171 7.70056 2.08192C7.6777 2.07212 7.65309 2.06706 7.62822 2.06704Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};
