export const DarkMode = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M18 10H19M1 10H2M10 18V19M10 1V2M15.657 15.657L16.364 16.364M3.636 3.636L4.343 4.343M4.343 15.657L3.636 16.364M16.364 3.636L15.657 4.343"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};
