import { useLayoutEffect } from "react";
import { useState } from "react";

export const useTheme = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("data-theme") || "lightTheme"
  );
  useLayoutEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("data-theme", theme);
  }, [theme]);
  return { theme, setTheme };
};
