export const ReportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M6.22209 4.77634C6.66665 4.47929 7.13344 4.22165 7.6171 4.00505C8.98898 3.39068 9.67491 3.08349 10.5875 3.67469C11.5 4.2659 11.5 5.2357 11.5 7.1753V8.6753C11.5 10.5609 11.5 11.5037 12.0858 12.0895C12.6716 12.6753 13.6144 12.6753 15.5 12.6753H17C18.9396 12.6753 19.9094 12.6753 20.5006 13.5878C21.0918 14.5004 20.7846 15.1863 20.1702 16.5582C19.9536 17.0419 19.696 17.5087 19.399 17.9532C18.3551 19.5155 16.8714 20.7331 15.1355 21.4522C13.3996 22.1712 11.4895 22.3593 9.64665 21.9928C7.80383 21.6262 6.11109 20.7214 4.78249 19.3928C3.45389 18.0642 2.5491 16.3715 2.18254 14.5287C1.81598 12.6858 2.00412 10.7757 2.72315 9.0398C3.44218 7.3039 4.65982 5.82021 6.22209 4.77634Z"
      stroke="#83829D"
      strokeWidth="1.5"
    />
    <path
      d="M21.446 7.24382C20.6342 5.18312 18.9917 3.5406 16.931 2.72879C15.3895 2.1215 14 3.51797 14 5.17483V9.17483C14 9.72711 14.4477 10.1748 15 10.1748H19C20.6569 10.1748 22.0533 8.78536 21.446 7.24382Z"
      stroke="#83829D"
      strokeWidth="1.5"
    />
  </svg>
);
