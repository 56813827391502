export const BotChat = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" fill="none">
      <g fill="#07046E">
        <path d="M1.938 15.033c-.185 0-.32.002-.453-.001-.61-.015-1.045-.402-1.052-1.006-.017-1.33-.015-2.66.004-3.988.008-.594.43-.98 1.018-1 .068-.003.136-.008.203 0 .24.026.31-.084.307-.314a87.325 87.325 0 0 1-.002-2.062c.01-1.224.884-2.107 2.109-2.128.484-.008.969-.001 1.453-.001h.373c-.055-.168-.09-.284-.132-.397-.344-.953-.693-1.904-1.032-2.86C4.565.8 4.698.394 5.079.149c.493-.317 1.085-.114 1.318.48.263.67.499 1.352.747 2.03.197.538.4 1.076.587 1.618.064.19.165.26.366.26 3.223-.006 6.445-.006 9.668 0 .225 0 .309-.094.378-.29.4-1.124.813-2.243 1.224-3.365.038-.106.075-.213.123-.315.218-.463.685-.677 1.11-.511.485.188.718.651.553 1.15-.18.546-.385 1.082-.58 1.622-.173.477-.348.953-.52 1.43-.025.07-.036.146-.067.274.252 0 .474-.005.695 0 .495.013.997-.018 1.482.058.99.154 1.673.987 1.688 1.988.011.71.013 1.42 0 2.13-.005.249.07.357.325.329.844-.091 1.225.607 1.216 1.239-.017 1.138-.003 2.276-.003 3.414 0 .943-.351 1.31-1.284 1.344-.065.002-.13.016-.238.03v.975c-.002.506.02 1.015-.015 1.519-.076 1.084-.943 1.893-2.03 1.93l-.068.001c-5.894 0-11.787.002-17.68-.002-1.006 0-1.83-.636-2.06-1.584a3.527 3.527 0 0 1-.077-.804c-.006-.552.013-1.104.02-1.655 0-.121-.011-.242-.02-.411Zm4.189 2.658c.972-.449 1.872-.86 2.768-1.282a.518.518 0 0 1 .476-.01c1.746.775 3.566.915 5.42.544 1.502-.3 2.835-.95 3.886-2.1 1.208-1.323 1.48-3.096.7-4.635-.592-1.165-1.552-1.948-2.71-2.491-2.07-.97-4.21-1.075-6.388-.428-1.173.349-2.213.942-3.054 1.852-1.518 1.64-1.576 3.887-.136 5.582.146.173.165.301.074.508-.346.792-.672 1.592-1.036 2.46ZM12.942 24.77H.674l-.304-.001c-.354-.006-.366-.014-.368-.357a169.04 169.04 0 0 1 0-1.656c.005-1.145.83-2.005 1.979-2.067.101-.005.203-.003.304-.003h21.291c.506 0 .991.069 1.412.372.566.407.88.954.888 1.659.007.574-.007 1.15.007 1.724.006.253-.091.351-.337.33-.1-.01-.203-.001-.303-.001H12.941Z" />
        <path d="M11.266 11.092a1.151 1.151 0 0 1-1.155 1.163 1.166 1.166 0 0 1-1.132-1.122c-.013-.635.518-1.185 1.143-1.184.614 0 1.14.526 1.144 1.143ZM14.549 11.105c-.001-.62.514-1.146 1.131-1.157.622-.01 1.16.535 1.155 1.172a1.167 1.167 0 0 1-1.153 1.135 1.152 1.152 0 0 1-1.133-1.15Z" />
      </g>
    </svg>
  );
};
