import {
  experimental_createPersister,
  type AsyncStorage,
} from "@tanstack/query-persist-client-core";
import { get, set, del, createStore, type UseStore } from "idb-keyval";
import { GC_TIME, STALE_TIME } from "../Pages/report/Dashboard/constants";
import { queryKeys } from "./queryKeys";

function newIdbStorage(idbStore: UseStore): AsyncStorage {
  return {
    getItem: async (key) => await get(key, idbStore),
    setItem: async (key, value) => await set(key, value, idbStore),
    removeItem: async (key) => await del(key, idbStore),
  };
}

export const createQueryPresister = (queryKey: string, storeName: string) => {
  return experimental_createPersister({
    storage: newIdbStorage(createStore(queryKey, storeName)),
    maxAge: STALE_TIME,
  });
};

export const createQueryPresisterProps = (
  queryKey: string,
  storeName: string
) => {
  return {
    staleTime: STALE_TIME,
    gcTime: GC_TIME,
    persister: createQueryPresister(queryKey, storeName) as any,
  };
};

export const deleteQueryPresister = () => {
  indexedDB.deleteDatabase(queryKeys.dashboard.getStatusInfo.getAll);
  indexedDB.deleteDatabase(queryKeys.dashboard.getCompanyInfo.getAll);
  indexedDB.deleteDatabase(queryKeys.dashboard.getCompanyInfoByFilter.getAll);
  indexedDB.deleteDatabase(queryKeys.dashboard.getAllMessagesInfo.getAll);
};
