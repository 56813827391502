import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import "./style.scss";
import { Popconfirm, PopconfirmProps, message } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  handleEdit: Function;
  handleDelete: Function;
};

export const TableEditDelete = ({ handleEdit, handleDelete }: Props) => {
  let { t } = useTranslation();

  const confirm: PopconfirmProps["onConfirm"] = async (e) => {
    try {
      await handleDelete();
      message.success(t("TableEditDelete.msgDeleteSuccess"));
    } catch (error) {
      message.error(t("TableEditDelete.msgDeleteFailed"));
    }
  };

  return (
    <p className="actions-cell">
      <span className="edit" onClick={() => handleEdit()}>
        <EditIcon /> {t("TableEditDelete.tableEditButton")}
      </span>

      <span className="delete">
        <DeleteIcon style={{ transform: "scale(0.8)" }} />
        <Popconfirm
          title={t("TableEditDelete.titleAlertDeleted")}
          // description="Are you sure you want to delete?"
          onConfirm={confirm}
          okText={t("TableEditDelete.titleAlertDeletedAcceptButton")}
          cancelText={t("TableEditDelete.titleAlertDeletedDeniedButton")}
        >
          <div
            className="delete-menu-item"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {t("TableEditDelete.tableDeleteButton")}
          </div>
        </Popconfirm>
      </span>
    </p>
  );
};
