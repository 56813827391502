import { message } from "antd";
import axios, { AxiosRequestConfig } from "axios";

const baseUrlPortal = process.env.REACT_APP_MAPPER_URL; // process.env.REACT_APP_MAPPER_URL; //'http://localhost:8081/api/', // Replace with your API's base URL
let paxios = axios.create();

const fullToken = localStorage.getItem("token");
const refreshTokenKey = localStorage.getItem("refresh-token");

export const errorCatch = () => {};

paxios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.message === "canceled") return Promise.reject(error);
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        try {
          const user = localStorage.getItem("user");
          const refreshTokenBody = new FormData();
          refreshTokenBody.append("refreshToken", refreshTokenKey!);
          refreshTokenBody.append("grant_type", "refresh_token");
          refreshTokenBody.append("userId", user ? user : "");

          const response = await axios.post(
            `${baseUrlPortal}/auth/refresh_token`,
            refreshTokenBody
          );

          const newAccessToken = response.data.accessToken;
          localStorage.setItem("token", newAccessToken);

          // Retry the original request with the new access token
          if (error.config) {
            error.config.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios.request(error.config);
          }
        } catch (refreshError) {
          message.error("Your session has expired. Please log in again.");
          localStorage.removeItem("token");
          window.location.reload();
        }
      } else if (status === 500) {
        message.error("Sorry, something went wrong.");
      }
    } else {
      console.error("Network error:", error.message);
      message.error("Network error. Please try again later.");
    }

    return Promise.reject(error);
  }
);

// paxios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (401 === error?.response?.status) {
//       localStorage.removeItem("token");
//       window.location.reload();
//     }

//     if (500 === error?.response?.status) {
//       message.error("Sorry. something went wrong.");
//     } else {
//       // message.error('Sorry. something went wrong.');`
//     }
//   }
// );

export default class Api {
  static async get(route: string, config?: AxiosRequestConfig) {
    return await this.execute(route, null, "get", config);
  }

  static async put(route: string, params?: any, config?: AxiosRequestConfig) {
    return await this.execute(route, params, "put", config);
  }

  static async post(route: string, params?: any, config?: AxiosRequestConfig) {
    return await this.execute(route, params, "post", config);
  }

  static async delete(
    route: string,
    params?: any,
    config?: AxiosRequestConfig
  ) {
    return await this.execute(route, params, "delete", config);
  }

  static async execute(
    route: string,
    params: any,
    verb: "post" | "get" | "delete" | "put",
    axiosConfig?: AxiosRequestConfig
  ) {
    let request: string[] = [`${baseUrlPortal}${route}`];
    //let request = ["https://localhost:44394/api/" + route];
    let config: AxiosRequestConfig = {
      ...axiosConfig,
      headers: axiosConfig?.headers
        ? {
            Authorization: `Bearer ${fullToken}`,
            Lang: "en",
            from: "WEB",
            ...axiosConfig?.headers,
          }
        : {
            Authorization: `Bearer ${fullToken}`,
            Lang: "en",
            from: "WEB",
            // 'Content-Type': 'application/json;',
          },
    };
    if (route === "login") {
      config = {
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      };
    }

    if (params) request.push(params);
    //@ts-ignore
    let result = await paxios?.[verb](...request, config);
    return result?.data;
  }
}
