import { Button, Form, Modal } from "antd";
import { ReactNode } from "react";


type Props = {
  openItem: any;
  setOpenItem: React.Dispatch<any>;
  children: ReactNode;
  setOuterFilters: React.Dispatch<React.SetStateAction<{}>>;
};

export const TableFiltersModal = ({
  openItem,
  setOpenItem,
  children,
  setOuterFilters,
}: Props) => {
  return (
    <Modal
      className="group-modal mini-form-modal"
      title={""}
      open={!!openItem}
      onOk={() => {}}
      onCancel={() => setOpenItem(null)}
      footer={[]}
      destroyOnClose={true}
    >
      <Form layout="vertical" onFinish={(values) => setOuterFilters(values)}>
        {children}
        <div className="modal-btns">
          <Button className="modal-btn done" type="primary" htmlType="submit">
            apply
          </Button>

          <Button
            type="default"
            htmlType="button"
            className="modal-btn cancel"
            onClick={() => setOpenItem(null)}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
