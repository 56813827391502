export const TableFilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.959 9.75C16.5448 9.75 16.209 9.41421 16.209 9V2C16.209 1.58579 16.5448 1.25 16.959 1.25C17.3732 1.25 17.709 1.58579 17.709 2V9C17.709 9.41421 17.3732 9.75 16.959 9.75Z"
        fill="#535353"
      />
      <path
        d="M7 12.5C5.34315 12.5 4 11.1569 4 9.5C4 7.84315 5.34315 6.5 7 6.5C8.65685 6.5 10 7.84315 10 9.5C10 11.1569 8.65685 12.5 7 12.5Z"
        fill="#535353"
      />
      <path
        d="M17 11.5C15.3431 11.5 14 12.8431 14 14.5C14 16.1569 15.3431 17.5 17 17.5C18.6569 17.5 20 16.1569 20 14.5C20 12.8431 18.6569 11.5 17 11.5Z"
        fill="#535353"
      />
      <path
        d="M6.20898 15C6.20898 14.5858 6.54477 14.25 6.95898 14.25C7.37319 14.25 7.70898 14.5858 7.70898 15V22C7.70898 22.4142 7.37319 22.75 6.95898 22.75C6.54477 22.75 6.20898 22.4142 6.20898 22V15Z"
        fill="#535353"
      />
      <path
        d="M16.959 22.75C16.5448 22.75 16.209 22.4142 16.209 22V20C16.209 19.5858 16.5448 19.25 16.959 19.25C17.3732 19.25 17.709 19.5858 17.709 20V22C17.709 22.4142 17.3732 22.75 16.959 22.75Z"
        fill="#535353"
      />
      <path
        d="M6.20898 2C6.20898 1.58579 6.54477 1.25 6.95898 1.25C7.37319 1.25 7.70898 1.58579 7.70898 2V4C7.70898 4.41421 7.37319 4.75 6.95898 4.75C6.54477 4.75 6.20898 4.41421 6.20898 4V2Z"
        fill="#535353"
      />
    </svg>
  );
};
