export enum InstanceTypes {
  WHATSAPP = "614c78efa03e5bc28a5ebb84",
  FACEBOOK = "63b6e7428c7f3e6207fb98c8",
  OFFLINE = "63a0485814cbbd085d850b58",
  INSTAGRAM = "63c4c38ebdf7cd86b7757f89",
  BUSINESS = "6419ae66b538623d79b162e6",
  INTERNAL = "6407b5976bb36501d606ac7a",
}

export enum MessageStatus {
  Error = "ERROR",
  Pending = "PENDING",
  Send = "SERVER_ACK",
  Delivered = "DELIVERY_ACK",
  Read = "READ",
  Played = "PLAYED",
}

export interface listFilters {
  filters: { search: string; [key: string]: any };
  paging: { page: number; pageSize: number };
  sorting: { [key: string]: 1 | -1 };
}

export interface exportResportsResposne {
  // this will be send if we have more than one object in data field so we will group them with the _id from allRows
  allRows?: [
    {
      _id: string; // the _id to group with
      name?: string; // name to use if wanted
    },
  ];
  // this field is responsible of columns render
  allFields: [
    {
      field: string | string[]; // the field name in the data object , it supports nested object by using string array like lodash get function
      label: string; // the render label of column,
      color?: string; // color of the coloum -- if set in children it will overide parent color
      isMillisecond?: boolean; // if true then this field should be converted to duration
      isDateTimeStamp?: boolean; // if true then this field should be converted to readable date
      splitted: {
        // if you need to split the value
        value: string; // split key
        index: number; // selected index after splitting
      };
      children?: exportResportsResposne["allFields"]; // if you want to group some columns
    },
  ];
  count?: number; // count of the data rows, it will only be returned if requested and will be need if you need to do backend pagintion
  // this is our data
  data: {
    [key: string]: {}[];
  };
}

export type ChatTabTypes = "All" | "Contacts" | "Groups";
