export const companyCards = [
  { label: "all instances", key: "instancesCount" },
  { label: "all users", key: "usersCount" },
  { label: "all contacts", key: "contactsCount" },
  { label: "all bots", key: "botsCount" },
  { label: "all speed messages", key: "speedMessagesCount" },
  { label: "all speed files", key: "speedFilesCount" },
  { label: "all broadcasts", key: "broadcastsCount" },
  { label: "all bulks", key: "bulksCount" },
];

export const STALE_TIME = 1000 * 60 * 60; // 1 hours
export const GC_TIME = 1000 * 30;
