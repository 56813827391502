import { message } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setReminder,
  setReminderModalClose,
  setReminderModalInfo,
  setReminderModalStep,
} from "../../redux/remindersReducer/action";
import { REMINDERS_MODAL_STEPS } from "../../redux/remindersReducer/remindersReducer";

export const useReminderHelper = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.setReducer.userInfo);
  const reminders = useSelector(
    (state: any) => state.reminders?.remindersByUsersId?.[userInfo?._id]
  );
  const reminderModalInfo = useSelector(
    (state: any) => state.reminders?.reminderModalInfo
  );

  const onSetReminder = (values: any) => {
    const date = dayjs()
      .add(values?.day ?? 0, "days")
      .add(values?.hour ?? 0, "hours")
      .add(values?.minute ?? 0, "minutes")
      .valueOf()
      .toString();

    dispatch(
      setReminder({
        userId: userInfo?._id,
        reminderDate: date,
        message: values?.note,
      })
    );
    message.success("Reminder is set successfully");
    dispatch(setReminderModalClose());
  };

  const onClose = () => {
    dispatch(setReminderModalClose());
  };

  const sortedReminders = useMemo<any>(() => {
    // sort the reminders by the reminderDate (the date is in milliseconds for easier calcalution)
    if (!reminders) return [];
    return Object.values(reminders)?.sort(
      (a: any, b: any) =>
        new Date(a.reminderDate).getTime() - new Date(b.reminderDate).getTime()
    );
  }, [reminders]);

  const checkReminders = useCallback(() => {
    // the idea is to sort every reminder in a queue by the reminderDate and then check if the first reminder's time is due and if its due, then remove it from the queue
    if (!sortedReminders) return;
    if (
      +sortedReminders[0]?.reminderDate < dayjs().valueOf() &&
      !reminderModalInfo
    ) {
      dispatch(setReminderModalInfo(sortedReminders[0]?.contactInfo));
      dispatch(setReminderModalStep(REMINDERS_MODAL_STEPS.MESSAGE));
    }
  }, [dispatch, sortedReminders, reminderModalInfo]);

  useEffect(() => {
    // Run the validity check immediately when the component mounts
    checkReminders();

    // Set up an interval to run the validity check every minute (60000 milliseconds)
    const intervalId = setInterval(() => {
      checkReminders();
    }, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [checkReminders]);

  return {
    reminders,
    sortedReminders,
    onSetReminder,
    onClose,
  };
};
