export const Avatar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#fff"
        d="M18.476 20.893C18.126 18.24 16.826 16.4 12 16.4c-4.827 0-6.126 1.841-6.476 4.493m12.952 0C21.218 18.893 23 15.654 23 12c0-6.075-4.925-11-11-11S1 5.925 1 12c0 3.654 1.782 6.892 4.524 8.893m12.952 0A10.95 10.95 0 0 1 12 23a10.95 10.95 0 0 1-6.476-2.107M15.3 9.8a3.3 3.3 0 1 1-6.6 0 3.3 3.3 0 0 1 6.6 0Z"
      />
    </svg>
  );
};
