import ReminderBellPng from "../assets/reminder-bell-icon.png";

export const BellIcon = () => {
  return (
    <img
      src={ReminderBellPng}
      alt="bell icon"
      className="reminder-modal-bell-icon"
    />
  );
};
