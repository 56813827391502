import { Spin } from "antd";
import "./style.scss"; // You will need to create a CSS file for styling

const Spinner = () => {
  return (
    <div className="spinner-container">
      <Spin />
    </div>
  );
};

export default Spinner;
