export const MiniCrmIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 18.5909C20.3136 18.4385 20.5862 18.2464 20.8284 18.0042C22 16.8327 22 14.947 22 11.1758C22 7.40454 22 5.51893 20.8284 4.34735C19.6569 3.17578 17.7712 3.17578 14 3.17578H10C6.22876 3.17578 4.34315 3.17578 3.17157 4.34735C2 5.51893 2 7.40454 2 11.1758C2 14.947 2 16.8327 3.17157 18.0042C3.41375 18.2464 3.68645 18.4385 4 18.5909"
        stroke="#83829D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.94955 16.2259C10.8806 15.2948 11.3461 14.8293 11.9209 14.799C11.9735 14.7962 12.0261 14.7962 12.0787 14.799C12.6535 14.8293 13.119 15.2948 14.0501 16.2259C16.0759 18.2517 17.0888 19.2646 16.8053 20.1386C16.7809 20.2137 16.7506 20.2868 16.7147 20.3571C16.2973 21.1756 14.8648 21.1756 11.9998 21.1756C9.13482 21.1756 7.70233 21.1756 7.28489 20.3571C7.249 20.2868 7.21873 20.2137 7.19436 20.1386C6.91078 19.2646 7.92371 18.2517 9.94955 16.2259Z"
        stroke="#83829D"
        strokeWidth="1.5"
      />
    </svg>
  );
};
