import { remindersReducerTypes } from "./remindersReducerTypes";

export const REMINDERS_MODAL_STEPS = {
  PROMPT: "PROMPT",
  REMINDER: "REMINDER",
  MESSAGE: "MESSAGE",
};

export type RemindersModalStepType =
  (typeof REMINDERS_MODAL_STEPS)[keyof typeof REMINDERS_MODAL_STEPS];

const initialState: {
  reminderModalInfo: any;
  reminderModalStep: RemindersModalStepType;
  remindersByUsersId: {
    [key: string]: {
      contactInfo: any;
      reminderDate: string;
      message?: string;
    };
  };
} = {
  reminderModalInfo: null,
  reminderModalStep: REMINDERS_MODAL_STEPS.PROMPT,
  remindersByUsersId: {},
};

export const remindersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case remindersReducerTypes.SET_REMINDER: {
      // main action to set reminder, it needs userId, contactId to achieve this structure { userId: { contactId: { reminderDate, message } } } to avoid duplicate reminders
      const { userId, reminderDate, message } = action.payload;
      const contactId = state?.reminderModalInfo?._id ?? "";
      return {
        ...state,
        remindersByUsersId: {
          ...state.remindersByUsersId,
          [userId]: {
            ...state.remindersByUsersId?.[userId],
            [contactId]: {
              contactInfo: state.reminderModalInfo,
              reminderDate,
              message,
            },
          },
        },
      };
    }

    case remindersReducerTypes.REMOVE_REMINDER: {
      // remove action, needs userId and contactId to find the exact reminder
      const { userId, contactId } = action.payload;
      const newState: any = {
        ...state,
        reminderModalStep: REMINDERS_MODAL_STEPS.PROMPT,
        remindersByUsersId: {
          ...state.remindersByUsersId,
          [userId]: {
            ...state.remindersByUsersId?.[userId],
          },
        },
      };
      if (newState?.remindersByUsersId?.[userId]?.[contactId]) {
        delete newState?.remindersByUsersId[userId][contactId];
      }
      return newState;
    }

    case remindersReducerTypes.SET_REMINDERS_MODAL_INFO: // set the contact info to be used in as an indicator for the modal open state and use clicked contact info for later use
      return {
        ...state,
        reminderModalInfo: action.payload,
      };

    case remindersReducerTypes.SET_REMINDER_MODAL_STEP: // to determine the modal step that currently is being displayed ex (PROMPT, REMINDER, MESSAGE)
      return {
        ...state,
        reminderModalStep: action.payload,
      };
    case remindersReducerTypes.SET_REMINDERS_MODAL_CLOSE: // close the modal and reset the the step to PROMPT
      return {
        ...state,
        reminderModalStep: REMINDERS_MODAL_STEPS.PROMPT,
        reminderModalInfo: null,
      };
    default:
      return state;
  }
};
