import React, { ReactNode, useEffect, useState } from "react";
import "./style.scss";
import { Table as AntTable, TableProps, Input, Button } from "antd";
import { SearchIcon } from "../../icons/searchIcon";
import { listFilters } from "../../types/types";
import { SorterResult } from "antd/es/table/interface";
import { useList } from "../../Hooks/configration/useList";
import { useTranslation } from "react-i18next";
import { TableFiltersModal } from "./TableFilters";
import { TableFilterIcon } from "../../icons/TableFilterIcon";

type TableCustomProps = {
  tableQueryKey?: string;
  tableTitle?: string;
  total?: number;
  containerClassName?: string;
  onSearch?: (searchText: string) => any;
  onTableFitlerChange?: (path: string, value: any) => void;
  tableFitler?: listFilters;
  onClickNewButton?: Function;
  buttonContent?: string | React.ReactNode;
  customHeaderContent?: React.ReactNode;
  additionalFilters?: ReactNode;
  visibleSearch?: boolean;
} & TableProps<any>;

const TOTAL_PER_PAGE = 10;
export const Table = ({
  tableQueryKey,
  tableTitle,
  total,
  onSearch,
  onClickNewButton,
  containerClassName,
  buttonContent,
  customHeaderContent,
  onTableFitlerChange,
  tableFitler,
  additionalFilters,
  visibleSearch,
  ...antTableProps
}: TableCustomProps) => {
  const [openFiltersModal, setOpenFiltersModal] = useState<any>();
  const [outerFilters, setOuterFilters] = useState({});
  // if there is table query key then the fetching data logic will be here inside the table component using useList
  // if not then the fetching happend out side this component and will get the data from outside
  // for example in most table we just provide tableQueryKey but in some table like export reports we don't and provide all data from outside
  const {
    filters,
    setListFilters,
    data: listData,
    isLoading: listLoading,
  } = useList(tableQueryKey);

  let listTotal = total || listData?.count;
  const listFilter = tableFitler || filters;
  const setTableFilters = onTableFitlerChange || setListFilters;
  let { t } = useTranslation();

  useEffect(() => {
    setListFilters("filters", {
      ...filters.filters,
      ...outerFilters,
    });
  }, [outerFilters]);

  return (
    <div className={"table " + (containerClassName ?? "")}>
      <div className="table-header">
        <div className="start">
          {tableTitle && (
            <h1 className="table-title">
              <span>{tableTitle}</span>{" "}
              {listTotal ? <span>( {listTotal} )</span> : ""}
            </h1>
          )}
        </div>
        <div className="end">
          {visibleSearch && <Input
                size="large"
                placeholder={t("table.search")}
                className="table-search"
                prefix={<SearchIcon />}
                suffix={
                  additionalFilters ? (
                      <div
                          className="table-filter-icon"
                          onClick={() => setOpenFiltersModal(true)}
                      >
                        <TableFilterIcon />
                        filter
                      </div>
                  ) : undefined
                }
                // value={tableFitler?.filters?.search}
                onChange={(e) => {
                  setTableFilters &&
                  setTableFilters(
                      "filters.search",
                      e.target.value.length > 2 ? e.target.value : ""
                  );
                  onSearch && onSearch(e.target.value);
                }}
            />
          }

          {onClickNewButton && (
            <Button className="add-new" onClick={() => onClickNewButton()}>
              {buttonContent ?? " + Add New"}
            </Button>
          )}
          {customHeaderContent && customHeaderContent}
        </div>
      </div>
      <AntTable
        pagination={{
          total: listTotal,
          showTotal: (total, range) => `${range[1]} of ${total} Entries`,
          defaultPageSize: listFilter?.paging.pageSize || TOTAL_PER_PAGE,
          current: listFilter?.paging.page,
          pageSizeOptions: [10, 20, 50],
          onChange: (page, pageSize) => {
            setTableFilters && setTableFilters("paging", { page, pageSize });
          },
        }}
        onChange={(paging, _, sorter) => {
          const sorting = sorter as SorterResult<any>; // fornow there is not multible sort
          let { field, order } = sorting;
          setTableFilters &&
            setTableFilters(
              "sorting",
              order ? { [field as string]: order === "ascend" ? 1 : -1 } : {}
            );
        }}
        rowKey={"_id"}
        dataSource={listData?.data}
        scroll={{ x: 1050 }}
        {...antTableProps} // will overide any antTableProps the have been set above (e.g. dataSource, loading, etc)
        loading={listLoading || antTableProps.loading}
      />
      {openFiltersModal && (
        <TableFiltersModal
          openItem={openFiltersModal}
          setOpenItem={setOpenFiltersModal}
          children={additionalFilters}
          setOuterFilters={setOuterFilters}
        />
      )}
    </div>
  );
};
