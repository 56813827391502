import {
  Alert,
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeReminder,
  setReminderModalClose,
  setReminderModalInfo,
  setReminderModalStep,
} from "../../redux/remindersReducer/action";
import { REMINDERS_MODAL_STEPS } from "../../redux/remindersReducer/remindersReducer";
import { useReminderHelper } from "./useReminderHelper";
import "./ReminderModal.scss";
import { setDirection } from "../ChatContent/chatRenderers";
import { setActiveChat } from "../../redux/action";
import { BellIcon } from "../../icons/BillIcon";

const ReminderModal = () => {
  // Redux
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state?.setReducer?.userInfo);
  const remindermModalInfo = useSelector(
    (state: any) => state?.reminders?.reminderModalInfo
  );
  const reminderModalStep = useSelector(
    (state: any) => state?.reminders?.reminderModalStep
  );
  const { sortedReminders, onSetReminder, onClose } = useReminderHelper();

  const [form] = Form.useForm();
  const noteWatcher = Form.useWatch("note", form);

  // Current Reminder
  const currentReminder = useMemo(() => {
    // getting the current reminder to display its data in the message step
    if (sortedReminders?.length === 0) return {};
    return sortedReminders?.[0];
  }, [sortedReminders]);

  return (
    <Modal
      className="reminder-modal"
      width={500}
      centered
      open={!!remindermModalInfo}
      closable={false}
      footer={null}
    >
      {/* prompt step */}
      {reminderModalStep === REMINDERS_MODAL_STEPS.PROMPT && (
        <Flex vertical align={"center"} justify={"center"} gap={12}>
          <Typography.Title
            style={{
              padding: "10px 20px",
              textAlign: "center",
              fontSize: "23px",
            }}
            level={4}
          >
            Would You Like To Set A Reminder To Follow Up On This Contact?
          </Typography.Title>
          <Flex gap={12}>
            <Button
              size="large"
              onClick={() =>
                dispatch(setReminderModalStep(REMINDERS_MODAL_STEPS.REMINDER))
              }
            >
              Yes Reminder Me
            </Button>
            <Button className="btn-ghost" onClick={onClose} size="large">
              No
            </Button>
          </Flex>
        </Flex>
      )}

      {/* form step */}
      {reminderModalStep === REMINDERS_MODAL_STEPS.REMINDER && (
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => onSetReminder(values)}
          className="reminder-form"
        >
          <Row gutter={[24, 24]} align="middle">
            <Col span={24}>
              <Typography.Title style={{ textAlign: "center" }} level={3}>
                Set A Reminder
              </Typography.Title>
              <Divider style={{ marginBottom: 0 }} />
            </Col>

            <Col span={24}>
              <h5 className="reminder-form-date-label">set reminder after</h5>
            </Col>
            <Col span={8}>
              {" "}
              <Form.Item
                label="Minute"
                name="minute"
                className="reminder-form-item"
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Hour"
                name="hour"
                className="reminder-form-item"
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Day" name="day" className="reminder-form-item">
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <>
                    <Typography.Text>Add Note</Typography.Text>{" "}
                    <Typography.Text type="secondary">
                      (Optional)
                    </Typography.Text>
                  </>
                }
                name="note"
              >
                <Input.TextArea
                  style={{
                    direction: !!noteWatcher
                      ? setDirection(noteWatcher)
                      : "ltr",
                  }}
                  rows={4}
                />
              </Form.Item>
            </Col>

            <Col
              span={24}
              style={{
                gap: "10vw",
                display: "flex",
              }}
            >
              <Button htmlType="submit" size="large">
                Yes, Remind Me
              </Button>
              <Button size="large" className="btn-ghost" onClick={onClose}>
                No
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      {/* message step */}
      {reminderModalStep === REMINDERS_MODAL_STEPS.MESSAGE && (
        <>
          <Avatar
            className="reminder-modal-avatar"
            size={100}
            src={currentReminder?.contactInfo?.profilePic}
          />
          <BellIcon />
          <div className="reminder-modal-message-content">
            <h3
              style={{ padding: "10px 20px", textAlign: "center" }}
              className="reminder-modal-message-title"
            >
              Don't Forget To Respond To{" "}
              <span>({currentReminder?.contactInfo?.name?.savedAs})</span>{" "}
              Coverstation Phone:
              {currentReminder?.contactInfo?.remoteJid?.split("@")?.[0]}
            </h3>

            {currentReminder?.message && (
              <Alert
                className="reminder-modal-message-alert"
                style={{
                  direction: !!currentReminder?.message
                    ? setDirection(currentReminder?.message)
                    : "ltr",
                }}
                message={currentReminder?.message}
                type="warning"
              />
            )}
            <Flex gap={12}>
              <Row>
                <Col span={24}>
                  <Button
                    size="large"
                    onClick={() => {
                      dispatch(
                        setReminderModalStep(REMINDERS_MODAL_STEPS.REMINDER)
                      ); // if the user clicks on Set Again, it basically restarts the same reminder flow
                      dispatch(
                        setReminderModalInfo(currentReminder?.contactInfo)
                      );
                    }}
                  >
                    Set Again
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    size="large"
                    className="btn-ghost"
                    onClick={() => {
                      dispatch(
                        removeReminder({
                          userId: userInfo?._id,
                          contactId: currentReminder?.contactInfo?._id,
                        })
                      );
                      dispatch(setReminderModalClose());
                    }}
                  >
                    Stop Reminder
                  </Button>
                </Col>
              </Row>

              <Button
                size="large"
                className="btn-go"
                onClick={() => {
                  dispatch(setActiveChat(currentReminder?.contactInfo));
                  dispatch(
                    removeReminder({
                      userId: userInfo?._id,
                      contactId: currentReminder?.contactInfo?._id,
                    })
                  );
                  dispatch(setReminderModalClose());
                }}
              >
                go to user
              </Button>
            </Flex>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ReminderModal;
