export const LastSeenIcon = ({ seen }: { seen?: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill={seen ? "#423CFF" : "#6f6f6f"}
        d="M11.533 4.2a.665.665 0 0 0-.94 0l-3.76 3.76.94.94 3.76-3.767a.668.668 0 0 0 0-.933Zm2.827-.007L7.773 10.78l-2.32-2.313a.665.665 0 1 0-.94.94L7.3 12.193c.26.26.68.26.94 0L15.3 5.14a.663.663 0 0 0 0-.94h-.007a.651.651 0 0 0-.933-.007ZM.747 9.413 3.533 12.2c.26.26.68.26.94 0l.467-.467-3.253-3.266a.664.664 0 0 0-.94 0 .672.672 0 0 0 0 .946Z"
      />
    </svg>
  );
};
