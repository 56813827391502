import FileViewer from "react-file-viewer";
import { Image } from "antd";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ReactAudioPlayer from "react-audio-player";
import { Contact } from "../../../icons/contact";
import { MinusCircleOutlined } from "@ant-design/icons";
import ReactImageGallery from "react-image-gallery";
import { handleMsgMention } from "../../../Utilis/helpers";
import vCardParser from "vcard-parser";

export const LocationPreview = ({
  location,
  width = 200,
  height = 200,
}: any) => {
  const position = [location?.lat, location?.long] as LatLngExpression;
  const customIcon = new L.Icon({
    iconUrl: "/assets/marker.png",
    iconSize: [38, 38],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  if (!location?.lat || !location?.long) return null;
  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: height, width: width, zIndex: 1 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker icon={customIcon} position={position}>
        {/* <Popup autoPan> */}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${location?.lat},${location?.long}`}
          target="_blank"
          rel="noreferrer"
        >
          Show Location
        </a>
        {/* </Popup> */}
      </Marker>
    </MapContainer>
  );
};

const getFileTypeFromUrl = (url: string) => {
  const match = url.match(/\.([0-9a-z]+)(?:[?#]|$)/i);

  return match ? match[1].toLowerCase() : "webm";
};

// const parseVCard = (vCardString: string) => {
//   const vCardLines = vCardString.split("\n");
//   const vCardData = {} as any;

//   vCardLines.forEach((line) => {
//     const [key, value] = line.split(":");
//     if (key && value) {
//       vCardData[key] = value;
//     }
//   });

//   return vCardData;
// };

export const handleGetLocation = (location: string) => {
  if (!location) return;
  return JSON.parse(location);
};

const linkText = (text: string) => {
  // Regular expression to match URLs
  const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s<]*/gi;

  // Replace URLs with anchor tags
  const linkedText = text.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );

  return linkedText;
};

export const setDirection = (text: string) => {
  const arabicRegex =
    /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]+/;
  const englishRegex = /[a-zA-Z]+/;
  const numberRegex = /^\d+/;
  const symbolsRegex = /[$-/:-?{-~!"^_`\[\]]/;
  const words = text.trim().split(/\s+/);
  // decide by first Alphabetical word
  let firstAlphabeticalIndex = 0;
  while (
    (numberRegex.test(words[firstAlphabeticalIndex]) ||
      symbolsRegex.test(words[firstAlphabeticalIndex])) &&
    !arabicRegex.test(words[firstAlphabeticalIndex]) &&
    !englishRegex.test(words[firstAlphabeticalIndex])
  ) {
    firstAlphabeticalIndex++;
  }
  if (arabicRegex.test(words[firstAlphabeticalIndex])) return "rtl";
  if (englishRegex.test(words[firstAlphabeticalIndex])) return "ltr";

  return "rtl";
};

export const RenderMessage = ({
  item,
  setAlbum,
  isAlbum,
  userInfo,
}: {
  item: any;
  setAlbum?: any;
  isAlbum?: { id: string; index: number };
  userInfo: any;
}) => {
  if (item?.isDeleted) {
    return (
      <div className="deleted-msg">
        <MinusCircleOutlined color="var(--notifi-color)" />{" "}
        {renderTextOnMessageType(item, userInfo)}
      </div>
    );
  }

  switch (item?.type) {
    case "text":
      const isUrl = (str: string) => {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
        return urlRegex.test(str);
      };

      return isUrl(item?.body) ? (
        <a href={item?.body} target="_blank" rel="noreferrer">
          {item?.body}
        </a>
      ) : (
        <p
          className="message-text-content"
          dangerouslySetInnerHTML={{
            __html: linkText(handleFormatText(item?.body)),
          }}
        />
      );

    case "image":
      return (
        <>
          <Image
            width={200}
            src={item.body}
            placeholder={<Image preview={false} src={item.body} width={200} />}
          />
          <span className="chat-img-caption">
            {item?.caption && item?.caption}
          </span>
        </>
      );

    case "images":
      return item?.body?.length > 1 ? (
        <div
          className={
            isAlbum?.id === item?._id
              ? "drop-image-msg images-albums"
              : "images-album-msg"
          }
        >
          {isAlbum?.id === item?._id ? (
            <>
              <span
                onClick={() => {
                  setAlbum({ id: "", index: 0 });
                }}
                className="remove-album-view"
              >
                x
              </span>
              <ReactImageGallery
                showPlayButton={false}
                showFullscreenButton={false}
                showNav={false}
                startIndex={isAlbum?.index}
                items={item?.body?.map((file: any) => {
                  return {
                    original: file,
                    thumbnail: file,
                    originalHeight: 150,
                    originalWidth: 150,
                  };
                })}
                showIndex={true}
                showThumbnails={true}
              />
            </>
          ) : (
            item?.body?.map((img: any, idx: number) => (
              <img
                key={idx}
                onClick={() => setAlbum({ id: item?._id, index: idx })}
                src={img}
                alt="Item"
              />
            ))
          )}

          {/*           
              we may want to use and images preview instead of image gallary lakes transition and buttom preview to handle zoom problem,
              it also will remove the need of isAlbum and setAlbum
              need to search how to scroll to an image, animation scroll, toolbarRender preview
          */}
          {/* <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
              toolbarRender: (orignalNode, info) => (
                <>
                  {orignalNode}
                  <span>our buttom preview images</span>
                </>
              ),
            }}

          >
            {item?.body?.map((img: any, idx: number) => (
              <Image width={100} src={img} />
            ))}
          </Image.PreviewGroup> */}

          <span className="chat-img-caption">
            {item?.caption && item?.caption}
          </span>
        </div>
      ) : (
        <>
          <Image
            width={200}
            src={item.body?.[0]}
            placeholder={
              <Image preview={false} src={item.body?.[0]} width={200} />
            }
          />
          <span className="chat-img-caption">
            {item?.caption && item?.caption}
          </span>
        </>
      );

    case "sticker":
      return <img className="sticker-img" src={item?.body} alt="Sticker" />;

    case "contact":
      let vCardData = vCardParser.parse(item.body);
      return (
        <div className="contact-info">
          <div className="contact-info">
            <Contact />
            <div className="contact-details">
              <p>{vCardData?.fn?.[0]?.value}</p>
              <div className="contact-phones">
                {vCardData?.tel?.map(({ value: phone }: any) => (
                  <a href={`tel:${phone}`}>{phone}</a>
                ))}
              </div>
            </div>
          </div>
        </div>
      );

    case "document":
      const docName = item?.body?.split("/");
      return (
        <>
          <div className="doc-icon">
            <img
              src={`/assets/${getFileTypeFromUrl(
                item?.body
              )?.toLowerCase()}.png`}
              alt="Icon"
            />
            <a href={item?.body} target="_blank" rel="noreferrer">
              {docName?.[docName?.length - 1]?.slice(0, 50)}
              {/* {item?.body?.slice(0, 50)}... */}
            </a>
          </div>
          <span className="chat-img-caption">
            {item?.caption && item?.caption}
          </span>
        </>
      );

    case "video":
      return (
        <>
          <FileViewer
            fileType={getFileTypeFromUrl(item?.body)}
            filePath={item?.body}
          />
          <span className="chat-img-caption">
            {item?.caption && item?.caption}
          </span>
        </>
      );

    case "audio":
      return <ReactAudioPlayer src={item?.body} controls />;

    case "location":
      return (
        <div
          onClick={() => {
            const url = `https://www.google.com/maps/search/?api=1&query=${
              handleGetLocation(item?.body)?.lat
            },${handleGetLocation(item?.body)?.long}`;
            window.open(url, "_blank");
          }}
        >
          <LocationPreview
            location={handleGetLocation(item?.body)}
            width="100%"
          />
        </div>
      );

    case "unread":
      return (
        <div id="unread-msgs" className="unread-msgs">
          <p>{item?.count} Unread Messages</p>
        </div>
      );

    default:
      return;
  }
};

const handleFormatText = (text: string) => {
  // Replace mention with <span class='mention'></span>
  const stringWithMentions = handleMsgMention(text, (match: any, p1: any) => {
    return `<span class='message-text-content mention' style="direction:${setDirection(
      p1
    )};">@${p1}</span>`;
  });

  // Replace *bold* with <strong>bold</strong>
  const boldRegex = /\*(.*?)\*/g;
  const stringWithBold = stringWithMentions.replace(
    boldRegex,
    "<strong>$1</strong>"
  );

  // make each line a span to handle it's direction alone
  let lines = stringWithBold.split(/\r\n|\n|\r/g).map((line) => {
    return `<span class="message-text-content" style="direction:${setDirection(
      line
    )};">${line}</span>`;
  });

  const stringWithLineBreaks = lines.join("\n");
  return stringWithLineBreaks;
};

export const renderTextOnMessageType = (
  item: any,
  userInfo: any,
  fromContacts?: boolean
) => {
  switch (item?.type) {
    case "text":
      if (item?.isDeleted && !userInfo?.canSeeDeletedMessage)
        return <p>this message was deleted.</p>;
      return fromContacts ? (
        <p>{handleMsgMention(item?.body)}</p>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: handleFormatText(item?.body) }} />
      );

    case "image":
      return <p>Image</p>;

    case "images":
      return <p>Image</p>;

    case "sticker":
      return <p>Sticker</p>;

    case "contact":
      return <p>Contact</p>;

    case "document":
      return <p>{getFileTypeFromUrl(item?.body)}</p>;

    case "video":
      return <p>Video</p>;

    case "audio":
      return <p>Audio</p>;

    case "location":
      return <p>Location</p>;

    default:
      return;
  }
};
