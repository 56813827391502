import { Layout, Menu, Switch } from "antd";
import { ChatIcon } from "../../icons/chatIcon";
import "./style.scss";
import { MenuSettings } from "../../icons/menuSetting";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SettingIcon } from "../../icons/SettingIcon";
import { useEffect, useRef, useState } from "react";
import { MiniCrmIcon } from "../../icons/MiniCrmIcon";
import { BulkIcon } from "../../icons/bulkIcon";
import { ReportIcon } from "../../icons/ReportIcon";
import { useResponsive } from "../../Hooks/useContext";
import { AddOnIcon } from "../../icons/AddOnIcon";
import { BotChat } from "../../icons/botChat";
import { BotMenu } from "../../icons/BotMenu";

export const SideBarModal = () => {
  const { isDesktop } = useResponsive();
  let [openKeys, setOpenKeys] = useState<string[] | undefined>([""]);
  let [open, setOpen] = useState(false);
  let [alwaysOpen, setAlwaysOpen] = useState(false);
  let openKeysRef = useRef<string[] | undefined>([""]);
  let sideBarRef = useRef<any>(null);

  const { userInfo } = useSelector((state: any) => state.setReducer);
  const { Sider } = Layout;
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  // close if click outside sidebar
  useEffect(() => {
    const handleOutSideClick = (e: MouseEvent) => {
      if (!sideBarRef.current?.contains(e.target) && !alwaysOpen) {
        setOpenKeys([""]);
        setOpen(false);
      }
    };
    window.addEventListener("mousedown", handleOutSideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [sideBarRef, alwaysOpen]);

  if (!isDesktop) return null;

  return (
    <Sider
      className={`awfar-side-modal ${open && "open"}`}
      trigger={null}
      collapsible
      width={52}
      collapsed={false}
      onClick={() => {
        setOpenKeys(openKeysRef.current);
        setOpen(true);
      }}
      ref={sideBarRef}
    >
      <Menu
        openKeys={openKeys}
        onOpenChange={(keys) => {
          setOpenKeys(keys);
          openKeysRef.current = keys;
        }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
      >
        <div className="always-open-switch">
          <span>always open</span>
          <Switch
            checked={alwaysOpen}
            onChange={() => setAlwaysOpen(!alwaysOpen)}
          />
        </div>
        <Menu.Item
          className={`${location?.pathname === "/" ? "active" : ""}`}
          onClick={() => history.push("/")}
          key="chat"
          icon={<ChatIcon />}
        >
          <p>{t("sideBar.Chat")}</p>
        </Menu.Item>

        {userInfo?.canLoginDashboard && (
          <>
            <Menu.SubMenu
              title={t("sideBar.bot")}
              key="bot"
              className="menu-setting-icon stroke"
              icon={<BotMenu />}
            >
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("bot/all") ? "active" : ""
                }`}
                key="all-bot"
                onClick={() => history.push("/bot/all")}
              >
                <p>{t("sideBar.All_Bot")}</p>
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}

        {userInfo?.canLoginDashboard && (
          <>
            <Menu.SubMenu
              title={t("sideBar.campaign")}
              key="bulk"
              className="menu-setting-icon stroke"
              icon={<BulkIcon />}
            >
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("bulk/messages") ? "active" : ""
                }`}
                key="bulk"
                onClick={() => history.push("/bulk/messages")}
              >
                <p>{t("sideBar.bulk")}</p>
              </Menu.Item>
              <Menu.SubMenu
                  title={t("sideBar.Broadcast")}
                  key="broadcast"
                  className="menu-setting-icon stroke"
              >
                  <Menu.Item
                      className={`menu-setting-icon ${
                        location?.pathname?.includes("broadcast/messages") && !location?.pathname?.includes("special")
                          ? "active"
                          : ""
                      }`}
                      key="broadcast"
                      onClick={() => history.push("/broadcast/messages")}
                    >
                      <p>{t("sideBar.Broadcast")}</p>
                  </Menu.Item>
                  <Menu.Item
                      className={`menu-setting-icon ${
                        location?.pathname?.includes("special")
                            ? "active"
                            : ""
                      }`}
                      key="special-broadcast"
                      onClick={() => history.push("/special/broadcast")}
                    >
                    <p>{t("sideBar.SpecialBroadcast")}</p>
                  </Menu.Item>
              </Menu.SubMenu>
            </Menu.SubMenu>
          </>
        )}

        {userInfo?.canLoginDashboard && (
          <>
            <Menu.SubMenu
              title={t("sideBar.configuration")}
              key="configuration"
              className="menu-setting-icon"
              icon={<MenuSettings />}
            >
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("users") ? "active" : ""
                }`}
                key="users"
                onClick={() => history.push("/configuration/users")}
              >
                <p>{t("sideBar.users")}</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("groups") ? "active" : ""
                }`}
                key="groups"
                onClick={() => history.push("/configuration/groups")}
              >
                <p>{t("sideBar.groups")}</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("chat-reason") ? "active" : ""
                }`}
                key="chat-reason"
                onClick={() => history.push("/configuration/chat-reason")}
              >
                <p>{t("sideBar.Chat_Reason")}</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("tags") ? "active" : ""
                }`}
                key="tags"
                onClick={() => history.push("/configuration/tags")}
              >
                <p>{t("sideBar.Tags")}</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("files") ? "active" : ""
                }`}
                key="files"
                onClick={() => history.push("/configuration/files")}
              >
                <p>Files</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("activity") ? "active" : ""
                }`}
                key="activity"
                onClick={() => history.push("/configuration/activity")}
              >
                <p>Activity</p>{" "}
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("channels") ? "active" : ""
                }`}
                key="channels"
                onClick={() => history.push("/configuration/channels")}
              >
                <p>{t("sideBar.channels")}</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("instances") ? "active" : ""
                }`}
                key="instances"
                onClick={() => history.push("/configuration/instances")}
              >
                <p>{t("sideBar.instances")}</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("stores") ? "active" : ""
                }`}
                key="stores"
                onClick={() => history.push("/configuration/stores")}
              >
                <p>stores</p>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              title={t("sideBar.minicrm")}
              key="miniCrm"
              className="menu-setting-icon stroke"
              icon={<MiniCrmIcon />}
            >
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("Branches") ? "active" : ""
                }`}
                key="Branches"
                onClick={() => history.push("/Branches/Branches")}
              >
                <p>{t("sideBar.Branches")}</p>
              </Menu.Item>

              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("inquiryType") ? "active" : ""
                }`}
                key="inquiryType"
                onClick={() => history.push("/inquiryType/inquiryType")}
              >
                <p>{t("sideBar.Inquiry_Type")}</p>
              </Menu.Item>

              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("ComplainTypes") ? "active" : ""
                }`}
                key="ComplainTypes"
                onClick={() => history.push("/ComplainTypes/ComplainTypes")}
              >
                <p>{t("sideBar.ComplainـTypes")}</p>
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}
        {userInfo?.user_type === 1 && userInfo?.canLoginDashboard && (
          <Menu.SubMenu
            title={t("sideBar.Setting")}
            key="settings"
            className="menu-setting-icon"
            icon={<SettingIcon />}
          >
            <Menu.Item
              className={`menu-setting-icon ${
                location?.pathname?.includes("done_chats") ? "active" : ""
              }`}
              key="done_Chats"
              onClick={() => history.push("/setting/done_chats")}
            >
              <p>{t("sideBar.done_chats")}</p>
            </Menu.Item>
            {userInfo?.group_id === 1 && (
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("sync_chats") ? "active" : ""
                }`}
                key="sync_chats"
                onClick={() => history.push("/setting/sync_chats")}
              >
                <p>{t("sideBar.sync_chats")}</p>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {userInfo?.canLoginDashboard && (
          <>
            <Menu.SubMenu
              title={t("sideBar.report")}
              key="report"
              className="menu-setting-icon"
              icon={<ReportIcon />}
            >
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("dashboard") ? "active" : ""
                }`}
                key="dashboard"
                onClick={() => history.push("/report/dashboard")}
              >
                <p>Dashboard</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("agents-reports") ? "active" : ""
                }`}
                key="agents-reports"
                onClick={() => history.push("/report/agents-reports")}
              >
                <p>Agents Reports</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("customers-reports")
                    ? "active"
                    : ""
                }`}
                key="customers-reports"
                onClick={() => history.push("/report/customers-reports")}
              >
                <p>Customers Reports</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("export-reports") ? "active" : ""
                }`}
                key="export-reports"
                onClick={() => history.push("/report/export-reports")}
              >
                <p>Export Reports</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("hanging-chat") ? "active" : ""
                }`}
                key="hanging-chat"
                onClick={() => history.push("/report/hanging-chat")}
              >
                <p>Hanging Chat</p>
              </Menu.Item>
              <Menu.Item
                className={`menu-setting-icon ${
                  location?.pathname?.includes("last-client-message")
                    ? "active"
                    : ""
                }`}
                key="last-client-message"
                onClick={() => history.push("/report/last-client-message")}
              >
                <p>Last Client Message</p>
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}
        {userInfo?.canLoginDashboard &&
          userInfo?.company_instance.hasOpenAi && (
            <>
              <Menu.SubMenu
                title={t("sideBar.Add-Ons")}
                key="Add-Ons"
                className="menu-setting-icon"
                icon={<AddOnIcon />}
              >
                <Menu.Item
                  className={`menu-setting-icon ${
                    location?.pathname?.includes("ai-assistants")
                      ? "active"
                      : ""
                  }`}
                  key="ai-assistants"
                  onClick={() => history.push("/add-ons/ai-assistants")}
                >
                  <p>{t("sideBar.AiـAgent")}</p>
                </Menu.Item>
              </Menu.SubMenu>
            </>
          )}
      </Menu>
    </Sider>
  );
};
