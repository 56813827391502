export const ArFlag = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="18.3 43.6 764.8 513.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m18.3 43.6h764.8v513.1h-764.8z" fill="#006d31" />

      <g fill="#fff">
        <path d="m535.4 408.6c-8.1 0-10.1 1.2-15.5.8s-16.8-1.4-16.8-1.4 0-4.2-1.2-6.4-4.2-4.2-6.9-3.2c-2.8 1-3 2.6-3 4.2 0 2.8 3.5 4.2 3.7 5.4 0 0-86.8-4-131.3-4-44.4 0-82.2 5.3-100.8 1.3 0 0 .7 11.9 32.5 12.6s84.9-.7 109.4-.7 84.9 2 90.2 2.7c0 0 .3 2.3-.5 3.1s-1 2-1 3.4.5 3.5 1.5 5.1 1.4 2.2 3.2 2.2c2.2 0 4.4-1.4 3.6-3.6 0 0 6.5-1 8.5-.4s5 2.2 8.5 2.2c3.6 0 9.9-1 11.9-2.2 0 0 .4 3.4 5.9 3.4s8.3-3.8 8.3-9.1c.1-5.3-2.1-15.4-10.2-15.4zm-5.8 18c-1 .6-8.5.9-8.5-.6-.1-1.5-1.8-2.8-2.9-2.8-3.4 0-2.2 1.8-4.4 1.8s-13.1-.2-13.1-.2-.8-2.8 1.4-3.4c0 0 8.4-.8 15.3-.8 8.4 0 13.9 1.6 13.9 1.6s-.7 3.8-1.7 4.4zm-303.8-238.5s.6-8.2-1.9-10.2c-2.3-1.8-3-4.3-2.3-5.4 1.7-3 4-3.1 5.6-2.2 2.1 1.2 3.3 4.6 3.2 7.3-.2 2.8-1.8 7.3-4.6 10.5zm-11.4 66.1s11.3-15.3 18.6-17.9c7.3-2.7 12.3-1 12.6 2.3s-2.3 5-7 6c-5.2 1.1-16.5 6.6-20.4 9.6-2.3 1.9-4.8 1.7-3.8 0zm2 14.3s-6.4 11.7-7 20.3c-.5 8 .7 13.3 6.6 13.3 6 0 8.6-7.6 8.6-11.3s-.7-3.3-2.3-3.3c-1.7 0-4.1 4.5-5.7 3.7-3.1-1.6-2.7-5.9-1.7-8.7 1.4-3.5 1.1-8.2 1.5-14z" />
        <path d="m213.4 189.7s-2.1 13.9-1.4 22.1 5.7 14.3 10.4 14.3c4.6 0 8.6-6.4 8.6-12.9 0-6.4.7-12.5 2.5-13.2s1.9 20.3 2.7 23.2c1.6 6.5 8.5 2.7 11.3 10 1.1 3 1.8 26.1 2.1 29.3.4 3.2.4 3.2 2.9-.4s4.6-12.9 5-18.6-1.4-14.3-2.5-17.1c-1.1-2.9.7-6.8 3.2-6.4s5.4 3.7 9.8 4.3c4.2.5 13.4-1.1 15.2-6.8s-5.7-21.1-5.4-23.6c.4-2.5 1.8 0 3.2-1.4s-3.2-9.6-6.1-10.4c-2.8-.7-7.9 1.8-6.8 6.8s7.5 14.3 7.9 19.6c.4 5.4-6.8 5.4-8.9 1.8-2.2-3.6-4.7-7.9-4.7-13.2 0-5.4 1-10.7-2.6-13.2s-6.9.3-5.9 7.8c1.1 7.5 5.9 19.3 1 18.2-1.6-.3-3.2-10-4.3-19.3s1-17.1-5-18.2c-4.6-.8-5.5 2.3-3.7 13.7s3.7 20.9 2 25.2c-1.8 4.3-2.9-7.9-3.2-15.7-.4-7.9-.7-9.1-5.4-8.1-7 1.5-10 10-10.4 19.3s-6 15.2-7.1 2.4c-1.2-12.8-1.2-13.5-4.4-19.5z" />
        <path d="m259.5 171.7c-.4-.5-3.1.6-3.2 3.5-.2 3.1 1 7.2 5.2 7.2s9.2-.6 9.2-4.6-1.9-6.6-2.5-6.5c-.6.2.8 2.9-.3 4.2s-2.8-.2-3.2-2-.2-2.3-.6-2.3c-.3 0-.6 1-.8 2.1s-2.2 3-3.5 2.3c-1.4-.7 0-3.6-.3-3.9zm124.1 52.3c-.4-.5-3 .6-3.2 3.5-.2 3.1 1 7.2 5.2 7.2s9.2-.6 9.2-4.6-1.9-6.6-2.5-6.5c-.6.2.8 2.9-.3 4.2s-2.8-.2-3.2-2-.2-2.3-.6-2.3c-.3 0-.6 1-.8 2.1s-2.2 3-3.5 2.3c-1.4-.7 0-3.5-.3-3.9zm71.3-21.9c-.4-.5-3.1.6-3.2 3.5-.2 3.1 1 7.2 5.2 7.2s9.2-.6 9.2-4.6-1.9-6.6-2.5-6.5c-.6.2.8 2.9-.3 4.2s-2.8-.2-3.2-2-.2-2.3-.6-2.3c-.3 0-.6 1-.8 2.1s-2.2 3-3.5 2.3.1-3.5-.3-3.9zm-124.7-17.1s1-2.6 1-7.1-2.1-6.5-5.6-6.5c-3.7 0-5.9 2.8-6.6 6.2-.5 2.1 0 5.8 3.4 7.5 0 0 .2 2.2-3.6 5.4-3.7 3.2-14 12.7-16.2 13-2.2.4-1.8.4-1.7 1 .4 2.6 18 1.5 28.6-16.9 0 0 1.7.5 2.3-.7s-1.6-1.9-1.6-1.9zm-4.6-2.7c-1 0-1.8-1.2-1.8-2.7s.8-2.7 1.8-2.7 1.8 1.2 1.8 2.7c-.1 1.5-.9 2.7-1.8 2.7zm-2.2 22.9c.2-1.7-.1-2.2-2.5-1.1-1.5.7-2.7 1.2-3.7 1.8s-.8.6-.6 1.7c.2 1 .8 8.5 4.4 9.6s7-1.3 7.6-3.5 1.4-3.8.9-4.2-4.6 5.4-6.4 3.3c-1.8-2.2-.2-4.3.3-7.6z" />
        <path d="m330.4 224.6c-.6-.2-3.8 2.5-5.4 4.6-1.5 2-.2 4.4-2.3 4.9-1.7.4-3.5-3.5-4.3-4.3s-1.3-1.2-2.6-.9-2.8 2-4.7 5.7c-1.8 3.8-4.5 3.9-6.1 1.5-2.6-3.9-.7-6.8-3.3-6.5s-3.2 4.6-5.2 1.8c-2-2.9-3.3-22-5.1-35.8-.2-1.2-.3-2 .1-2.2 1.6-.8 4.9 1.6 5.6.6.6-1 .4-2.8-1.4-6.8s-5.5-14.6-8.2-15.4c-2.6-.9-5.8 8.2-5.3 11.8s6.7 36.1 6.6 44.5c-.2 8.4-.6 9.6-2.5 9.6s-2.6-3.2-5.4-5.1c-2.7-1.9-4.6-3-7.4-3-4.8 0-10.5 5.1-11.9 10.2-1.4 5-.5 14.1 5.2 15.2 5.1 1 9.6-1.3 11.5-2.7.9-.6 1.4-1.1 1.6-.9s.5 3.4-2.1 7.5-13 14.7-22.6 14.7-11.1-9.7-11.1-14.8.5-4.7.5-6.1-1.4-3.6-2.6-3.4c-1.1.2-3.3 3.2-3.3 12.3s4.7 22.4 16.6 22.4 24.8-7.6 31.1-29.9c1.2-4.3.4-5.5 1.2-6 .6-.4 2.2 3.7 2.5 6.3.3 3.9-.9 35.9-35.6 35.9 0 0-7-.1-9.6-1.8s-5.6-6.6-7.4-14.8-1.2-11.4-1.9-11c-.7.3-4.9 5.6-4.9 15.8s.9 23.4 22.2 23.4c17.9 0 33.4-10.1 39.5-21.9s4.9-29.3 4.9-31.3 1.9-1.8 2.1-.6 2.5 1.5 3.7 1.6 4.1-.2 5.7-1.2 4.3-4.1 5.8-4.1 1.8 2.5 6.2 2.5c4.5 0 8-1.7 9.7-14.6.7-5.6.6-7.5-.1-7.7zm-58.5 20.2c-1.9.2-3.6-.9-3.8-2.4s1.3-3 3.2-3.2 3.6.9 3.8 2.4c.1 1.5-1.3 2.9-3.2 3.2z" />
        <path d="m313.4 261.1s-3.3-.5-4.5-3.5c-1.2-3.1 2.4-5 4-5.2 1.7-.2 5.7 2.4 5 7.8s-2.4 10.2-3.3 9.9c-.9-.2-.7-6.6-.5-8.3.3-1.6-.7-.7-.7-.7zm35.9-94.5c1.5-.3 2 1.7 2.7 2.5s.5 1.1-.2 1.6-3.7 3.6-4 5c-.4 1.5 1.9 2.2 3 1.3.8-.7 2 .8 1.9 1.8-.2 1.8-1.7 3.9-5.1 3.9s-5-1.9-4.9-4.2c.1-1.4 1.6-6.7 3-8.6 1.5-1.9 3.1-3.2 3.6-3.3zm22.9 2c3-.8 5.3 3.4 4.7 5.4s-1 4.4-1.8 5.8-1.5 3-1.4 3.8c.2.8-.7 1.3-1 1.3s-2.8.6-3.2-.9c-.4-1.4-.2-3.5.5-4.8s1-4.2.4-5.6-.6-4.3 1.8-5z" />
        <path d="m333.5 193.9c0 9.4 5.9 23.7 13.8 23.1 6.5-.4 7.4-9.4 8.9-14s1.5-6.6 2.4-6.8 1.3 15.1 3.5 18.1c2.2 3.1 4.8 6.1 8.7 6.1s8.7-.9 12.7-5.9c3.9-5 4.8-5.7 7.9-1.1s5.7 5.4 8.1 5.4 9.9-.7 12-8.7c1.1-4.1 3.1-2.6 3.7-.7.6 2 0 5 1.3 5s5-3.1 6.3-5.5 2.4-5.2 1.5-7c-.9-1.7-2.6 0-4.4 1.1s-.7 1.7-2 1.7-4.8 1.3-5.5-2-3.3-10-5-13.1c-1.8-3-1.8-7 .2-6.8s4.8 2.4 5.9 1.5-3.3-4.8-3.7-8.3-3.7-8.1-7.6-6.6-7.6 7-3.9 15.3 5.4 12.3 5.7 14c.7 4.2-2.2 10-6.1 9.2-3.9-.9-6.1-5.7-6.5-11.8s-2.6-21.4-3.7-24.9-1.5-3.9-2.6-3.3c-1.1.7-5.9 3.1-5.2 8.1s3.3 8.1 3.3 8.1 2.4 14 .9 15.9c-1.5 2-5 8.1-8.3 8.3-4.4.2-9.4-4.1-9.4-8.5s2-19.6-2.2-22.7c-4.1-3.1-8.5 5-13.1 15.5s-1.5 14.2-5.5 13.1c-3.9-1.1-4.6-9-5.7-15.7-1.1-6.8-1.8-9.4-3.1-8.5-1.3 1.3-3.3 6.7-3.3 12.4zm13.6 44.3c1-3.2 2.9-1 3.5-4.5s-4.2-10.2-8-10.5-9.9 5.1-6.1 14 7.7 10.5 7 13.4c-.6 2.9-1.9 7.7-8.6 14-6.7 6.4-29 20.1-35.7 24.2s-13.1 9.2-12.1 10.5 12.1-2.6 18.2-4.1c6.1-1.6 30.4-12.8 40.5-26.2 10.8-14.3 5.1-23 3.5-25.5-1.5-2.4-2.2-5.3-2.2-5.3z" />
        <path d="m431.9 254.5c-8-1.3-22.3 1.6-27.4 1s-6.7-2.5-1.6-4.2c5.1-1.6 6.7-.3 9.3-.6 8.9-1.3 12.8-7.3 14.3-15 1.6-7.6-5.4-15-10.8-15.6s-12.8 3.2-13.4 8 2.2 9.6 1.6 10.8c-.6 1.3-6.4.6-16.9 5.4s-12.4 8.6-14.3 10.8-.6-.3-1-3.8c-.3-3.5-7.7-24.2-10.8-29-3.2-4.8-4.5-4.8-5.4-1.6-1 3.2-1.3 10.5.3 16.3 1.6 5.7 6.7 14.4 6.4 21.4s-3 21.9-26.5 30.6c-6.1 2.2-12.1 3.5-10.2-9.9s2.9-17.5 1.9-19.1c-.8-1.4-13.1 16.9-9.6 29.7 4.5 16.3 24.2 16.6 35.7 2.9s13.1-15.6 14.7-13.1c1.6 2.6 8.3 15.3 27.4 18.2 10.9 1.6 11.5-.6 11.5-4.2 0-3.5-2.6-5.7-5.1-6.4-2.5-.6-7.6-3.8-7.6-6.1 0-2.2-.6-5.7 11.5-7.7 12.1-1.9 20.4-4.1 23.3-4.5 2.9-.3 6.4-2.9 5.1-4.2s-.6-3.5 1.6-5.4c2.1-1.9 4-3.5-4-4.7zm-21-22.7c.7-.5 2 .1 2.8 1.4s.9 2.7.1 3.2c-.7.5-2-.2-2.8-1.4-.8-1.3-.9-2.7-.1-3.2zm-20.1 38.3c-2.5 1-4.8 5.1-4.5 7 .7 4.1-.3 5.3-6 2.6-8-3.8-7-13.4-7.7-14.7-.6-1.3.5-2.6 2.2-2.9 2.2-.3 8.6.3 12.4 1 3.8.6 8.9 2.5 8.9 4.1.1 1.6-3 2-5.3 2.9z" />
        <path d="m414.7 286.1c1.7-1.4 3.4-3 6.3-4.1 2.9-1 2.2.8 1.8 2.1s-.6 6.6 1.1 7 7.4-.9 8.2-2.1.8-1.8 1.4-1.7 3 1.8 5.1 2.9c2.2 1 .8 1.5-3.3 2.3s-9.5 3.4-12.6 3.4-3.1-2.6-3.4-4.3c-.2-1.7-.9-2.5-2.2-2.2-1.4.2-2.6.3-3-.8-.3-1.2.6-2.5.6-2.5zm128.6 7.6c1-1.9 2-4 4-5.6s1.8.6 1.7 2.1.7 7.5 2 7.7 5.3-2.1 5.7-3.6.3-2.1.8-2.1 2.5 1.6 4.3 2.5c1.8.8.8 1.6-2.1 3.1s-6.5 5.3-8.8 5.8c-2.3.4-2.8-2.5-3.2-4.4-.5-1.9-1.1-2.7-2-2.2-1 .5-1.9.8-2.4-.4-.4-1.2 0-2.9 0-2.9zm-104.8-115.8c-1.6-2.5-.3-5.7-2.5-5.7s-3.1 2.5-3.1 4.1-.5 8.5 2.2 12.2c1.6 2.2 1.9 2.8 1.9 4.7s5.6 34.2 5.6 68.1c0 15.7-1.9 16.9-1.6 18.8s.6 7.8 1.6 8.5c.9.6 6.3-5 6.9-14.4s0-44.9-4.4-75.6c-.6-4.4 2.2.3 2.8-1.3s-.4-12.3-3.5-14.1c-2.9-1.8-4-2.5-5.9-5.3zm51.5 0c-1.6-2.5-.3-5.7-2.5-5.7s-3.1 2.5-3.1 4.1-.5 8.5 2.2 12.2c1.6 2.2 1.9 2.8 1.9 4.7s7.6 30.2 8.7 60.2c.6 16.6-.4 29.5.6 30.1.9.6 4.4-3.8 5-13.2s-1.7-41-6.1-71.7c-.6-4.4 2.2.3 2.8-1.3s-.4-12.3-3.5-14.1c-3-1.8-4.1-2.5-6-5.3z" />
        <path d="m416 172.9c-2.6-1.6-2.8-5.1-4.9-4.1-2 1-1.8 3.7-1.1 5.2s3.4 8.1 7.6 10.4c2.5 1.3 3 1.8 3.9 3.5.8 1.7 20.7 29.3 36 60.8 7.1 14.6 5.9 16.6 7 18.2s4.1 7 5.3 7.2 3.6-7.5-.1-16.5-20.3-41.8-38.2-68.4c-2.6-3.8 2.2-.7 2.1-2.4s-5.9-11.3-9.6-11.6c-3.6-.3-5-.5-8-2.3z" />
        <path d="m482.8 276.2c1.3-5.8-.4-35-.8-40.9-.3-5.9-1.4-24.5-2.9-36.6-.5-4.4 2.2.4 2.9-1.2s-.1-12.3-3.2-14.2c-3-1.8-4-2.6-5.9-5.5-1.6-2.5-.2-5.7-2.4-5.7s-3.2 2.5-3.2 4c0 1.6-.7 8.5 2 12.3 1.5 2.2 1.8 2.9 1.8 4.7 0 1.9 5.6 33.5 4.8 67.4-.3 14.2-1.9 15.8-5.5 19.9-5.5 6.3-16.7 15.5-20.4 16.4s-4.7 1.4-3.7 3.1c.9 1.5 5.9 3.4 9.6 3.4s6.9 0 12.1-3.8c5.6-4 11.7-8.6 14.8-23.3zm26.9-66.6c-1.2 2.4-2.9 7-1.6 9.3 1.3 2.2 8 4.5 9.9 7.7s2.9 2.9 3.2 1-.6-9.3 2.6-13.4c1.4-1.9 4.2-1.9 4.5-4.8s-2.2-4.8-4.2-5.1c-1.9-.3-4.8-.3-5.1 1.6s-1.6 11.2-2.9 10.2-2.9-3.8-3.8-5.4c-1-1.8-2-2.4-2.6-1.1zm8.7 38.8c-3 2.1-5.7 3.4-6 3l-2.2-4.4c-.2-.5 2-2.6 5.1-4.7l11.3-7.9c3-2.1 5.7-3.4 6-3l2.2 4.4c.2.5-2 2.6-5.1 4.7zm12.7-5.1c-1.7-.9-3.9 3.7-3.9 6.2 0 3.8 3.6 7.5 4.5 8.5 1.3 1.5 1.5 4.8.1 6.1-1.3 1.2-2.6 1.5-1.5 2.4 1.1.8 5.7-3.3 5.7-6.3s-1.9-6.6-2.7-8.2 0-3 0-4.6c.1-1.8-.6-3.3-2.2-4.1z" />
        <path d="m578.5 261.5c-1.8-4.2-6.6-12.7-13.2-23.1-1.1-11.2-2.8-24.6-4.9-39.3-.6-4.4 2.2.3 2.8-1.3s-.4-12.3-3.5-14.1c-3-1.8-4.1-2.5-6-5.3-1.6-2.5-.3-5.6-2.5-5.6s-3.1 2.5-3.1 4.1-.5 8.5 2.2 12.2c1.6 2.2 1.9 2.8 1.9 4.7 0 1.2 2.8 14.1 4.8 31.9-3.9-5.9-8.1-12.1-12.5-18.3-.4-3-.8-5.9-1.2-8.8-.6-4.4 2.2.3 2.8-1.3s-.4-12.3-3.4-14.1-4.1-2.5-6-5.3c-1.6-2.5-.3-5.7-2.5-5.7s-3.1 2.5-3.1 4.1c0 1.3-.3 6.1 1 9.9-.4-.1-.7-.3-1-.3-3.8-.1-5.2-.2-8.4-1.8-2.8-1.4-3-5-5.1-3.9-2.2 1.1-1.9 3.9-1.2 5.3s3.5 7.9 7.9 10c2.6 1.2 3.2 1.6 4.1 3.3.4.7 3.9 4.6 8.9 10.8 1.6 13 3.5 32.1 3.5 51.8v3.5c-.4 2.6-1.3 5.6-3.2 7.9-2.9 3.4-5.6 5.9-9.5.4-3.9-5.4-6.9-10.3-9.9-9.3s-5.9 3.9-8.3 11.6-8.6 18.9-11.6 15.9-7-5.7-7-14.1c0-8.3 1.4-9.5.9-11.5-.6-2-5.3 5.7-5.6 15.2s3.9 17.3 12.3 21.4c8.4 4 11.7-5.9 12.8-9 1-3.1 2.2-12.6 3.9-13.5 2-1 5.5 0 6 .9.6.9 3.7 5 6.9 6.3 3 1.2 8.6 2.8 12.6.7 4.4-2.3 6.7-5.7 7.6-12 .5-3.5.6-8.6.3-11.8 0-10.5-1.1-26.5-2.9-43.5 3.9 5.1 8.1 11 12.2 17.2.6 7.4 1 15.2 1 23.2 0 15.7 0 20.2 1.6 20.4 1.1.1 5.2-7.1 5.8-16.5.2-3 .1-7.7-.3-13.7 7.6 13.7 12.2 24.6 13.3 24.7 1.3.3 2.6-5.5-1.2-14.3zm-12.1-76.1c.9-1.1 1.4-1.5 2.6.4 1.2 2 3.7 6.9 3.7 14.7s-2.7 8.3-3.6 8.4c-.8.1.4-1.2 1.1-3.8s.4-4.6-1.3-8.1c-1.8-3.6-4.3-5.2-4.9-5.8s-.7-1.1 0-2.2 2.1-3.2 2.4-3.6z" />
        <path d="m586.5 197.1c-.6-4.2 2.2.3 2.8-1.2s-3-6.7-4.9-9.4c-1.6-2.4-3.1-7.6-5.3-7.6s-3.1 2.4-3.1 4c0 1.5-.5 8.2 2.2 11.9 1.6 2.1 1.9 2.8 1.9 4.6s6.6 35.3 6.6 68.2c0 13.3-2.2 14.2-2.5 15.4-1.6 2.5-4.1 5.3-8.1 8.4-10.5 7.9-15.8 9.8-16.4 11.2s.1 1.6 1.8 2c1.7.5 13.9-1.3 20.8-6.7 8-6.4 9.7-14.2 9.9-21.5.1-9.3-1.3-49.5-5.7-79.3z" />
      </g>
    </svg>
  );
};
