export const AddOnIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.30218 1.30224C2.03991 0.564473 3.04048 0.15 4.08379 0.15H11.0857C12.129 0.15 13.1296 0.564473 13.8673 1.30224C14.6051 2.04001 15.0195 3.04065 15.0195 4.08401V11.0852C15.0195 12.1285 14.6051 13.1292 13.8673 13.867C13.1296 14.6047 12.129 15.0192 11.0857 15.0192H9.47854V12.9855H11.0857C11.5897 12.9855 12.073 12.7853 12.4294 12.4289C12.7857 12.0725 12.9859 11.5892 12.9859 11.0852V4.08401C12.9859 3.58003 12.7857 3.09668 12.4294 2.74031C12.073 2.38393 11.5897 2.18372 11.0857 2.18372H4.08495C3.58099 2.18372 3.09767 2.38393 2.74131 2.74031C2.38496 3.09668 2.18476 3.58003 2.18476 4.08401V11.0852C2.18476 11.5892 2.38496 12.0725 2.74131 12.4289C3.09767 12.7853 3.58099 12.9855 4.08495 12.9855H4.51485V15.0192H4.08379C3.04048 15.0192 2.03991 14.6047 1.30218 13.867C0.564453 13.1292 0.15 12.1285 0.15 11.0852V4.08401C0.15 3.04065 0.564453 2.04001 1.30218 1.30224ZM8.57179 8.57111C8.21544 8.92749 8.01524 9.41083 8.01524 9.91482V16.916C8.01524 17.42 8.21544 17.9033 8.57179 18.2597C8.92815 18.6161 9.41147 18.8163 9.91544 18.8163H16.9162C17.4202 18.8163 17.9035 18.6161 18.2599 18.2597C18.6162 17.9033 18.8164 17.42 18.8164 16.916V9.91482C18.8164 9.41083 18.6162 8.92749 18.2599 8.57111C17.9035 8.21474 17.4202 8.01453 16.9162 8.01453H16.4886V5.9808H16.9162C17.9595 5.9808 18.9601 6.39528 19.6978 7.13305C20.4355 7.87082 20.85 8.87145 20.85 9.91482V16.916C20.85 17.9594 20.4355 18.96 19.6978 19.6978C18.9601 20.4355 17.9595 20.85 16.9162 20.85H9.91544C8.87213 20.85 7.87156 20.4355 7.13383 19.6978C6.3961 18.96 5.98165 17.9594 5.98165 16.916V9.91482C5.98165 8.87145 6.3961 7.87082 7.13383 7.13305C7.87156 6.39528 8.87213 5.9808 9.91544 5.9808H11.5145V8.01453H9.91544C9.41147 8.01453 8.92815 8.21474 8.57179 8.57111Z"
        fill="#83829D"
        stroke="#D9D9D9"
        strokeWidth="0.3"
      />
    </svg>
  );
};
