export const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M9 21C13.4183 21 17 17.4183 17 13C17 8.58172 13.4183 5 9 5C4.58172 5 1 8.58172 1 13C1 14.2355 1.28008 15.4056 1.7802 16.4502C1.95209 16.8093 2.01245 17.2161 1.90955 17.6006L1.58151 18.8267C1.32295 19.793 2.20701 20.677 3.17335 20.4185L4.39939 20.0904C4.78393 19.9876 5.19071 20.0479 5.54976 20.2198C6.5944 20.7199 7.76449 21 9 21Z"
        stroke="#83829D"
        strokeWidth="1.5"
      />
      <path
        d="M17 13.5018C17.0665 13.4741 17.1324 13.4453 17.1977 13.4155C17.5598 13.2501 17.9661 13.1882 18.3506 13.2911L18.8267 13.4185C19.793 13.677 20.677 12.793 20.4185 11.8267L20.2911 11.3506C20.1882 10.9661 20.2501 10.5598 20.4155 10.1977C20.7908 9.376 21 8.46242 21 7.5C21 3.91015 18.0899 1 14.5 1C11.7977 1 9.4806 2.64899 8.5 4.9956"
        stroke="#83829D"
        strokeWidth="1.5"
      />
      <path
        d="M5.51758 13H5.52658M9.0083 13H9.0173M12.4993 13H12.5083"
        stroke="#83829D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
