import { ConfigProvider, Layout, message, theme } from "antd";
import { Header } from "./Component/header";
import "./styles/index.scss";
import { SocketContextProvider } from "./Socket";
import { SideBarModal } from "./Component/sideModal";
import "./styles/_reset.scss";
import "./styles/index.scss";
import Login from "./Pages/Login";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { Router, useHistory, useLocation } from "react-router-dom";
import { RouterWrapper } from "./router/routerWrapper";
import { useDispatch } from "react-redux";
import {
  refreshMessages,
  setActiveVendor,
  setChatLoading,
  setInstanceList,
  setUserInfo,
} from "./redux/action";
import Api from "./Network";
import { ConnectionAlert } from "./Component/ConnectionAlert/ConnectionAlert";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { getInitialTheme } from "./Utilis/getCurrentTheme";
// import { locale } from "dayjs";
import { useTheme } from "./Hooks/useTheme";
import { InstanceTypes } from "./types/types";
import ReminderModal from "./Component/ReminderModal/ReminderModal";
import { ResponsiveProvider, useResponsive } from "./Hooks/useContext";

function App() {
  const { Content } = Layout;
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isDesktop } = useResponsive();

  const handleLoadUserData = useCallback(async () => {
    try {
      const response = await Api.get("user");
      dispatch(setUserInfo(response?.user));
      localStorage.setItem("user", response?.user?._id);
      const lastActiveVendorId = localStorage.getItem("last-active-vendor-id")!;
      const lastActiveVendor = lastActiveVendorId
        ? response?.user?.instances?.find(
            (instance: any) => instance._id === lastActiveVendorId
          )
        : null;
      // if the online have phone then the active instance should be of whatsApp type
      if (pathname.includes("phone")) {
        let activeVendor;
        if (lastActiveVendor?.type === InstanceTypes.WHATSAPP) {
          activeVendor = lastActiveVendor;
        } else {
          activeVendor = response?.user?.instances?.find(
            (instance: any) => instance.type === InstanceTypes.WHATSAPP
          );
        }
        dispatch(setActiveVendor(activeVendor || response?.user?.instances[0]));
      } else {
        dispatch(
          setActiveVendor(lastActiveVendor || response?.user?.instances[0])
        );
        dispatch(setChatLoading(true));
      }

      dispatch(setInstanceList(response?.user?.instances));
      // handleLoadVendorChats(1, 20, false, response?.user?.instances[0]?._id);
    } catch (error) {
      message.error("failed to load user data");
      console.error("Error fetching data:", error);
    }
  }, []);

  useLayoutEffect(() => {
    if (!token) return;
    handleLoadUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ScrollToTop = () => {
    useEffect(() => {
      const unListen = history.listen(() => {
        window.scrollTo(0, 0);
      });

      return () => {
        unListen();
      };
    }, [history]);

    return null;
  };

  useEffect(() => {
    if (!pathname?.includes("login") || isDesktop) return;
    if (token) history.replace("/");
  }, [history]);

  const { theme: currentTheme } = useTheme();
  // TODO: Add more congigurations
  const queryClient = new QueryClient();
  return (
    <div className="App light-theme">
      {token ? (
        <SocketContextProvider>
          <ConfigProvider
            theme={{
              algorithm:
                currentTheme === "lightTheme"
                  ? theme.compactAlgorithm
                  : theme?.darkAlgorithm,
            }}
          >
            <QueryClientProvider client={queryClient}>
              <Header />
              <Layout>
                <SideBarModal />
                <ReminderModal />
                <Layout className="site-layout">
                  <Content className="site-layout-background">
                    <Router history={history}>
                      <ScrollToTop />
                      <RouterWrapper />
                      <ConnectionAlert />
                    </Router>
                  </Content>
                </Layout>
              </Layout>
            </QueryClientProvider>
          </ConfigProvider>
        </SocketContextProvider>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
