const initialState = {
  userInfo: null,
  chatReasons: null,
  speedFiles: null,
  speedMessages: null,
  driversList: null,
  activeVendor: null,
  activeComment: null,
  loadMore: false,
  groupsList: null,
  instanceList: null,
  vendorChats: null,
  activeChat: null,
  forwardList: null,
  selectedChats: [],
  postsList: null,
  showSelectedChats: null,
  selectedMessages: {},
  isCommentsView: null,
  commentsList: null,
  activePost: null,
  siteMuted: false,
  groupParticipants: [],
  refreshMessages: false,
  isChatLoading: false,
  chatTabType: "All",
  listPaging: {
    pageSize: 20,
    pageNumber: 1,
  },
  groupParticipantsPaging: {
    pageSize: 20,
    pageNumber: 1,
  },
  messageListPaging: {
    pageSize: 20,
    pageNumber: 1,
  },
};

export const setReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_COMMENTS_VIEW":
      return { ...state, isCommentsView: action.payload };
    case "SET_COMMENTS_LIST":
      return { ...state, commentsList: action.payload };
    case "SET_POSTS_LIST":
      return { ...state, postsList: action.payload };
    case "SET_ACTIVE_COMMENT":
      return { ...state, activeComment: action.payload };
    case "SET_FORWARD_LIST":
      return { ...state, forwardList: action.payload };
    case "SET_INSTANCE_LIST":
      return { ...state, instanceList: action.payload };
    case "SET_USER_INFO":
      return { ...state, userInfo: action.payload };
    case "SET_VENDOR_LIST":
      return {
        ...state,
        vendorChats: action.payload,
        forwardList: action.payload,
      };
    case "SET_ACTIVE_CHAT":
      return { ...state, activeChat: action.payload };
    case "SET_CHAT_REASONS":
      return { ...state, chatReasons: action.payload };
    case "SET_SPEED_FILES":
      return { ...state, speedFiles: action.payload };
    case "SET_DRIVERS_LIST":
      return { ...state, driversList: action.payload };
    case "SET_ACTIVE_VENDOR":
      return { ...state, activeVendor: action.payload };
    case "SET_ACTIVE_POST":
      return { ...state, activePost: action.payload };
    case "SET_LOADING":
      return { ...state, loadMore: action.payload };
    case "SET_SELECTED_CHAT":
      return { ...state, selectedChats: action.payload };
    case "SET_GROUPS_LIST":
      return { ...state, groupsList: action.payload };
    case "SET_SHOW_SELECTED":
      return { ...state, showSelectedChats: action.payload };
    case "SET_SPEED_MESSAGE":
      return { ...state, speedMessages: action.payload };
    case "SET_CHAT_TAB_TYPE":
      return { ...state, chatTabType: action.payload };
    case "SET_PAGING_SIZE":
      return {
        ...state,
        listPaging: {
          pageNumber: action.payload?.pageNumber,
          stopLoading: action.payload?.stopLoading,
        },
      };
    case "SET_GROUP_PARTICIPANTS_PAGING_SIZE":
      return {
        ...state,
        groupParticipantsPaging: {
          pageNumber: action.payload?.pageNumber,
          stopLoading: action.payload?.stopLoading,
        },
      };
    case "SET_PAGING_MESSAGES":
      return {
        ...state,
        messageListPaging: {
          pageNumber: action.payload?.pageNumber,
          stopLoading: action.payload?.stopLoading,
        },
      };
    case "TOGGLE_SITE_MUTED":
      return {
        ...state,
        siteMuted: !state.siteMuted,
      };
    case "SET_GROUP_PARTICIPANTS":
      return {
        ...state,
        groupParticipants: action.payload,
      };
    case "REFRESH_MESSAGES":
      return {
        ...state,
        refreshMessages: action.payload,
      };
    case "SET_CHAT_LOADING":
      return {
        ...state,
        isChatLoading: action.payload,
      };
    case "SET_SELECTED_MESSAGES":
      return {
        ...state,
        selectedMessages: action.payload,
      };
    default:
      return state;
  }
};
