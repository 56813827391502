/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Menu, message } from "antd";
import { Avatar } from "../../icons/avatar";
import { BotChat } from "../../icons/botChat";
import { Crm } from "../../icons/crm";
import { ArFlag } from "../../icons/arFlag";
import { EnFlag } from "../../icons/enFlag";
import { LightMode } from "../../icons/DarkModeIcons/lightMode";
import { DarkMode } from "../../icons/DarkModeIcons/DarkMode";

import "./style.scss";
import Api from "../../Network";
import { ChangePasswordIcon } from "../../icons/changePasswordIcon";
import { LogoutIcon } from "../../icons/logoutIcon";

import { DashboardIcon } from "../../icons/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/action";
import { ImageWithChecker } from "../ImageChecker";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Hooks/useTheme";
import React, { useState, useEffect } from "react";
import { ChangePasswordModal } from "../ChangePasswordModal";
import FullScreenIcon from "../../icons/FullScreenIcon";
import { queryKeys } from "../../Utilis/queryKeys";
import { deleteQueryPresister } from "../../Utilis/createQueryPresister";
import { getInitialTheme } from "../../Utilis/getCurrentTheme";
import { useResponsive } from "../../Hooks/useContext";

export const Header = () => {
  const { userInfo } = useSelector((state: any) => state.setReducer);
  const dispatch = useDispatch();
  const { isDesktop } = useResponsive();

  const { t, i18n } = useTranslation();
  const [openChangePassword, setOpenChangePassword] = useState<any>();
  const [theme, setTheme] = useState<string>(getInitialTheme());

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleThemeChange = () => {
    const newTheme = theme === "lightTheme" ? "darkTheme" : "lightTheme";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  async function handleLogout() {
    try {
      await Api.post("auth/logout", {
        company: userInfo?.company_instance?._id,
      });
      localStorage.removeItem("token");
      localStorage?.removeItem("socketUrl");
      localStorage?.removeItem("refresh-token");
      dispatch(setUserInfo(null));

      deleteQueryPresister();
      window.location.reload();
    } catch (error) {
      message.error("failed to logout");
    }
  }

  // Get initial theme from localStorage or default to "lightTheme"

  function handleFullScreen() {
    if (!isFullScreen) return document.body.requestFullscreen();
    return document.exitFullscreen();
  }

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullScreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang) i18n.changeLanguage(lang);
  }, []);

  const menu = (
    <Menu className="header-account-menu">
      <Menu.Item
        key="1"
        onClick={() => {
          handleLogout();
        }}
      >
        <span className="icns-drop-header">
          <LogoutIcon />
        </span>
        {t("header.Log_out")}
      </Menu.Item>

      <Menu.Item key="2" onClick={() => setOpenChangePassword(true)}>
        <span className="icns-drop-header">
          <ChangePasswordIcon />
        </span>
        {t("general.Change_Password")}
      </Menu.Item>
    </Menu>
  );

  if (!isDesktop) return null;

  return (
    <header className="app-header">
      <div className="logos-container">
        <img src="./assets/awfarLogo.webp" alt="Awfar" />
        <span className="header-logos-border">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1"
            height="38"
            viewBox="0 0 1 38"
            fill="none"
          >
            <line
              x1="0.5"
              y1="2.18557e-08"
              x2="0.499998"
              y2="38"
              stroke="white"
              strokeOpacity="0.2"
            />
          </svg>
        </span>
        {userInfo?.company_instance?.logo ? (
          <ImageWithChecker
            imageUrl={userInfo?.company_instance?.logo}
            defaultIcon="./assets/logoColored.webp"
            useSrc={true}
          />
        ) : (
          <div className="no-logo-placeholder">
            <div>
              {userInfo?.company_instance?.nickName ||
                userInfo?.company_instance?.name}
            </div>
          </div>
        )}
      </div>

      <div className="header-links">
        <a>
          <span>
            <BotChat />
          </span>
          {t("header.Bot_Chat")}
        </a>

        {/* 
        <a>
          <span>
            <Crm />
          </span>
           {t('header.CRM')}
        </a>
        <a>
          <span>
            <DashboardIcon />
          </span>
          
          {t('header.Dashboard')}
        </a> */}
      </div>

      <div className="header-center">
        <button onClick={() => handleFullScreen()}>
          <FullScreenIcon />
        </button>
        <button onClick={handleThemeChange}>
          {theme === "lightTheme" ? <LightMode /> : <DarkMode />}
        </button>

        {i18n.language == "en" && (
          <a
            onClick={() => {
              i18n.changeLanguage("ar");
              localStorage.setItem("lang", "ar");
            }}
          >
            <span>
              <ArFlag />
            </span>
          </a>
        )}

        {i18n.language == "ar" && (
          <a
            onClick={() => {
              i18n.changeLanguage("en");
              localStorage.setItem("lang", "en");
            }}
          >
            <span>
              <EnFlag />
            </span>
          </a>
        )}

        <div className="profile-icon">
          <Dropdown
            destroyPopupOnHide={true}
            overlay={menu}
            trigger={["click"]}
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar />
              <p className="ptofile-text">{userInfo?.name}</p>
            </div>
          </Dropdown>
        </div>

        <ChangePasswordModal
          setOpenItem={setOpenChangePassword}
          openItem={openChangePassword}
          userId={userInfo?._id}
        />
      </div>
    </header>
  );
};
