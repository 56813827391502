import { RemindersModalStepType } from "./remindersReducer";
import { remindersReducerTypes } from "./remindersReducerTypes";

export const setReminder = (value: {
  userId: string;
  reminderDate: string;
  message?: string;
}) => ({
  type: remindersReducerTypes.SET_REMINDER,
  payload: value,
});
export const removeReminder = ({
  userId,
  contactId,
}: {
  userId: string;
  contactId: string;
}) => ({
  type: remindersReducerTypes.REMOVE_REMINDER,
  payload: { userId, contactId },
});

export const setReminderModalInfo = (value: any) => ({
  // any refers to contact info
  type: remindersReducerTypes.SET_REMINDERS_MODAL_INFO,
  payload: value,
});

export const setReminderModalStep = (value: RemindersModalStepType) => ({
  type: remindersReducerTypes.SET_REMINDER_MODAL_STEP,
  payload: value,
});

export const setReminderModalClose = () => ({
  type: remindersReducerTypes.SET_REMINDERS_MODAL_CLOSE,
});
