export const LogoutIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50146 5.83366C7.51156 4.02113 7.59193 3.03954 8.23225 2.39923C8.96451 1.66699 10.143 1.66699 12.5 1.66699H13.3333C15.6903 1.66699 16.8689 1.66699 17.6011 2.39923C18.3333 3.13146 18.3333 4.30997 18.3333 6.66699V13.3337C18.3333 15.6907 18.3333 16.8692 17.6011 17.6014C16.8689 18.3337 15.6903 18.3337 13.3333 18.3337H12.5C10.143 18.3337 8.96451 18.3337 8.23225 17.6014C7.59193 16.9611 7.51156 15.9795 7.50146 14.167"
        stroke="#E31F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.4998 10H1.6665M1.6665 10L4.58317 7.5M1.6665 10L4.58317 12.5"
        stroke="#E31F1F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
