export const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        stroke="#79747E"
        d="M14.324 14.883c.362.36.92-.195.559-.548l-2.953-2.944a6.17 6.17 0 0 0 1.606-4.158C13.536 3.795 10.725 1 7.268 1S1 3.795 1 7.233c0 3.438 2.811 6.233 6.268 6.233 1.56 0 3-.572 4.103-1.52l2.953 2.937ZM1.787 7.233c0-3.007 2.464-5.45 5.48-5.45 3.024 0 5.48 2.443 5.48 5.45s-2.456 5.45-5.48 5.45c-3.016 0-5.48-2.443-5.48-5.45Z"
      />
    </svg>
  );
};
