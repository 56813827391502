import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { io, Socket } from "socket.io-client";

type SocketEvent =
  | "connect"
  | "disconnect"
  | "connect_error"
  | "new-message"
  | "update-message"
  | "remove-contact"
  | "add-contact"
  | "post"
  | "comment";

type SocketResponse = {
  error?: string;
  result?: string;
};

type Context = {
  connected: boolean;
  emit: <T>(
    event: SocketEvent,
    data: T,
    callback?: (res: SocketResponse) => void
  ) => void;
  subscribe: <T extends { [key: string]: any; data?: any[] }>(
    event: SocketEvent,
    callback: (data: T) => void
  ) => void;
  unsubscribe: <T>(event: SocketEvent, callback: (data: T) => void) => void;
  socket: Socket | null;
};

type Props = {
  children: ReactNode;
};

const SocketContext = createContext<Context | null>(null);

const token = localStorage.getItem("token");
const socketUrl = localStorage.getItem("socketUrl");

const socketOptions = {
  autoConnect: true,
  transports: ["websocket", "polling"],
  auth: {
    token: `Bearer ${token}`,
  },
  // reconnectionAttempts: 15,
  reconnectionDelayMax: 10000,
  withCredentials: true,
};

export const SocketContextProvider = ({ children }: Props) => {
  const { userInfo } = useSelector((state: any) => state.setReducer);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connected, setConnected] = useState(false);
  const [pingActive, setPingActive] = useState(true);

  const emit = useCallback(
    (event: SocketEvent, data: any, cb?: (res: SocketResponse) => void) => {
      socket?.emit(event, data, cb);
    },
    []
  );

  const subscribe = useCallback(
    (event: SocketEvent, listener: (data: any) => void) => {
      socket?.on(event, listener);
    },
    []
  );

  const unsubscribe = useCallback(
    (event: SocketEvent, listener: (data: any) => void) => {
      socket?.off(event, listener);
    },
    []
  );

  const connect = useCallback(() => {
    if (!token || !socketUrl || !userInfo) return;
    const socket = io(socketUrl, {
      ...socketOptions,
      query: {
        userId: userInfo?._id,
      },
    });

    socket.on("connect", () => {
      console.log("socket connected successfully");
      setConnected(true);
    });

    socket.on("pong", () => setPingActive(true));

    socket.on("disconnect", (reason) => {
      console.log("disconnect event with reason, ", reason);

      setConnected(false);

      if (
        reason === "io server disconnect" ||
        reason === "io client disconnect"
      ) {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    const cleanupSocket = connect();
    return () => {
      cleanupSocket?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect]);

  useEffect(() => {
    if (!socket) return;
    const timer = setTimeout(() => {
      if (!pingActive) setConnected(false);
      setPingActive(false);
      socket.emit("ping");
    }, 90000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, socket, pingActive]);

  return (
    <SocketContext.Provider
      value={{
        connected,
        subscribe,
        unsubscribe,
        emit,
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (!context)
    throw Error("Error using SocketContext outside SocketContextProvider");
  return context;
};
