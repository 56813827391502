import { Button, Form, Input, Modal, Switch, Upload, message } from "antd";
import "./style.scss";
import { useEffect } from "react";
import Api from "../../Network";
import { talbeQueryKeys } from "../../Hooks/configration/tableQueryKeys";
import { useCreate, useUpdate } from "../../Hooks/configration/useList";
import { InfiniteSelect } from "../InfiniteSelect/InfiniteSelect";
import { useQuery } from "@tanstack/react-query";
import { UploadOutlined } from "@ant-design/icons";
import { AIAssistant } from "../../types/AiAssistant";

type Props = {
  openItem: AIAssistant;
  setOpenItem: React.Dispatch<any>;
};

export const AiAssistantModal = ({
  openItem: aiAssistant,
  setOpenItem,
}: Props) => {
  let isEdit = !!aiAssistant._id;

  let { mutateAsync: addAiAssistant, isPending: addAiAssistantLoading } =
    useCreate(talbeQueryKeys.aiAssistant);
  let { mutateAsync: updateAiAssistant, isPending: updateAiAssistantLoading } =
    useUpdate(talbeQueryKeys.aiAssistant);

  let [form] = Form.useForm();

  const startDateWatcher = Form.useWatch("canTransfer", form);

  async function saveAiAssistant(aiAssistantData: any) {
    try {
      let body = new FormData();

      body.append("name", aiAssistantData.name);
      body.append("description", aiAssistantData.description);
      body.append("instructions", aiAssistantData.instructions);
      body.append("channel", aiAssistantData.channel);
      body.append("canDone", aiAssistantData.canDone);
      body.append("canTransfer", aiAssistantData.canTransfer);
      body.append("transferGroup", aiAssistantData.transferGroup);
      if (aiAssistantData.groups)
        body.append("groups", JSON.stringify(aiAssistantData.groups));
      if (aiAssistantData?.files)
        aiAssistantData?.files?.forEach((file: any) =>
          body.append("files", file.originFileObj)
        );

      if (isEdit) {
        await updateAiAssistant({
          id: aiAssistant._id,
          data: body,
        });
      } else {
        await addAiAssistant(body);
      }
      message.success("Ai Agent Saved Successfully");
      setOpenItem(null);
    } catch (error: any) {
      console.log(error);

      message.error(
        error.response?.data?.error ||
          error.response?.data?.errorMessage ||
          "failed to save the Ai Agent"
      );
    }
  }

  const { data: editedAssistant, isPending: getAssistantLoading } = useQuery<{
    user: any;
    aiAssistant: any;
    openAiAssistant: any;
  }>({
    queryKey: ["get Ai-Assistant", aiAssistant._id],
    queryFn: () =>
      Api.get(
        `${talbeQueryKeys.aiAssistant}/get-ai-assistant?id=${aiAssistant._id}`
      ),
    enabled: !!isEdit && !!aiAssistant._id,
  });

  useEffect(() => {
    if (!editedAssistant) return;
    form.setFieldValue(
      "groups",
      editedAssistant?.user?.groups.map((group: any) => group._id)
    );
    form.setFieldValue("channel", editedAssistant?.user?.channel?._id);
  }, [editedAssistant]);

  return (
    <Modal
      className="aiAssistant-modal mini-form-modal"
      title={isEdit ? "Edit Ai Agent" : "New Ai Agent"}
      open={!!aiAssistant}
      onOk={() => {}}
      onCancel={() => setOpenItem(null)}
      footer={[]}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(aiAssistant) => saveAiAssistant(aiAssistant)}
      >
        <Form.Item
          label="name"
          name="name"
          initialValue={aiAssistant.name ?? undefined}
          rules={[{ required: true, max: 256 }]} // open ai limits
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="description"
          name="description"
          initialValue={aiAssistant.description ?? undefined}
          rules={[{ required: true, max: 512 }]} // open ai limits
        >
          <Input.TextArea count={{ show: true, max: 512 }} rows={6} />
        </Form.Item>
        <Form.Item
          label="instructions"
          name="instructions"
          initialValue={aiAssistant.instructions ?? undefined}
          rules={[{ required: true, max: 256000 }]} // open ai limits
        >
          <Input.TextArea count={{ show: true, max: 256000 }} rows={6} />
        </Form.Item>
        <Form.Item label="groups" name="groups">
          <InfiniteSelect
            queryKey={talbeQueryKeys.group}
            loading={isEdit && getAssistantLoading}
            initialOptions={editedAssistant?.user?.groups}
          />
        </Form.Item>

        <Form.Item label="channel" name="channel" rules={[{ required: true }]}>
          <InfiniteSelect
            queryKey={talbeQueryKeys.channel}
            loading={isEdit && getAssistantLoading}
            mode={undefined} // to override the multiple mode inside the component
          />
        </Form.Item>
        <div className="more-features">
          <Form.Item
            layout="horizontal"
            label="can done"
            name="canDone"
            valuePropName="checked"
            initialValue={!!aiAssistant?.canDone}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            layout="horizontal"
            label="can transfer"
            name="canTransfer"
            valuePropName="checked"
            initialValue={!!aiAssistant?.canTransfer}
          >
            <Switch />
          </Form.Item>
        </div>
        {startDateWatcher && (
          <Form.Item
            label="transfer group"
            name="transferGroup"
            tooltip="this is the group which this ai-agent will transfer the contacts to, if needed"
            initialValue={aiAssistant?.transferGroup?._id}
          >
            <InfiniteSelect
              queryKey={talbeQueryKeys.group}
              loading={isEdit && getAssistantLoading}
              initialOptions={
                aiAssistant?.transferGroup ? [aiAssistant?.transferGroup] : []
              }
              mode={undefined}
            />
          </Form.Item>
        )}
        {isEdit ? (
          <span>
            {
              editedAssistant?.openAiAssistant?.tool_resources?.file_search
                ?.vector_store_ids?.length
            }{" "}
            uploaded files (you cant edit files for now)
          </span>
        ) : (
          <Form.Item
            valuePropName="fileList"
            label="files"
            name="files"
            getValueFromEvent={(e) => {
              console.log("Upload event:", e);
              if (Array.isArray(e)) {
                return e;
              }
              return e?.fileList;
            }}
          >
            <Upload>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        )}

        <div className="modal-btns">
          <Button
            loading={addAiAssistantLoading || updateAiAssistantLoading}
            className="modal-btn done"
            type="primary"
            htmlType="submit"
          >
            {isEdit ? "Edit" : "Add"}
          </Button>

          <Button
            type="default"
            htmlType="button"
            className="modal-btn cancel"
            onClick={() => setOpenItem(null)}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
