export const BulkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
  >
    <path
      d="M6.66797 22.1758H14.2242"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8906 11.8928V13.6769C19.8906 16.9828 18.0016 18.3996 15.168 18.3996H5.72266C2.88906 18.3996 1 16.9828 1 13.6769V8.00976C1 4.70391 2.88906 3.28711 5.72266 3.28711H8.55625C8.41982 3.68591 8.34635 4.12669 8.34635 4.59896V8.69195C8.34635 9.70992 8.68219 10.5705 9.28039 11.1687C9.87859 11.7669 10.7392 12.1027 11.7572 12.1027V13.5615C11.7572 14.0967 12.3659 14.4221 12.8171 14.1282L15.8501 12.1027H18.5788C19.051 12.1027 19.4918 12.0293 19.8906 11.8928Z"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9889 4.59635V8.68934C21.9889 10.253 21.1913 11.4285 19.89 11.8902C19.4912 12.0267 19.0504 12.1001 18.5781 12.1001H15.8495L12.8165 14.1256C12.3652 14.4195 11.7565 14.0941 11.7565 13.5589V12.1001C10.7385 12.1001 9.87794 11.7643 9.27974 11.1661C8.68154 10.5679 8.3457 9.70731 8.3457 8.68934V4.59635C8.3457 4.12409 8.41917 3.68331 8.5556 3.2845C9.01737 1.98315 10.1928 1.18555 11.7565 1.18555H18.5781C20.6246 1.18555 21.9889 2.54987 21.9889 4.59635Z"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4453 18.3926V22.1707"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3125 6.69336H18.3282"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.373 6.69336H15.3888"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4336 6.69336H12.4493"
      stroke="#83829D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
