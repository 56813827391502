import { useTranslation } from "react-i18next";
import "./style.scss";
import { useSocketContext } from "../../Socket";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStopwatch } from "react-timer-hook";
import Api from "../../Network";
import { refreshMessages, setInstanceContacts } from "../../redux/action";
import _ from "lodash";
import { ChatListFilter } from "../../Utilis/chatsList";

export const ConnectionAlert = () => {
  const [show, setShow] = useState(false);
  const { connected } = useSocketContext();
  const { t } = useTranslation();
  const { userInfo, activeVendor, vendorChats, activeChat, messageListState } =
    useSelector((state: any) => state.setReducer);
  const activeVendorRef = useRef(activeVendor);
  const vendorChatsRef = useRef(vendorChats);
  const activeChatRef = useRef(activeChat);
  const messageListStateRef = useRef(messageListState);

  const dispatch = useDispatch();
  const { handleLoadVendorChats } = ChatListFilter();

  // don't hide alert if it's disconnected more than 3 seconds
  let { reset, pause, totalSeconds } = useStopwatch();

  // const MAX_DISCONNECT_TIME = 3;

  useEffect(() => {
    // don't render this component at first
    let timer = setTimeout(() => {
      setShow(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (connected || !show || !userInfo) {
      pause();
    } else {
      reset();
    }
    if (totalSeconds > 1 && (connected || !show || !userInfo)) {
      handleLoadVendorChats();

      dispatch(refreshMessages(true)); // trigger refetch messages in useChat hook
    }
  }, [connected, show, userInfo]);

  useEffect(() => {
    activeVendorRef.current = activeVendor;
  }, [activeVendor]);
  useEffect(() => {
    activeChatRef.current = activeChat;
  }, [activeChat]);
  useEffect(() => {
    messageListStateRef.current = messageListState;
  }, [messageListState]);
  useEffect(() => {
    vendorChatsRef.current = vendorChats;
  }, [vendorChats]);

  if (connected || !show || !userInfo) return null;

  return (
    <div className="connection-alert">
      <div className="alert">
        {t("general.connection_alert", { seconds: totalSeconds })}
        <p>{totalSeconds} seconds</p>
        {/* <span onClick={() => window.location.reload()}>Refresh Now</span> */}
      </div>
    </div>
  );
};
