import { createStore, applyMiddleware, Store } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import rootReducer from "./root-reducer";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkMiddleware<
  RootState,
  any,
  ReturnType
>;

export type AppStore = Store<RootState>;

let middlewares: any[] = [];

middlewares = [thunk];

const store: AppStore = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

store.subscribe(() => {
  // console.log("Actions:", store.getState());
});

const persistor = persistStore(store);

export { store, persistor };
